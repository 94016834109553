import React from "react";
import "./Footer.css";
import {AppleStoreBadge} from "../SvgImages/AppleStoreBadge/AppleStoreBadge";
import {AndroidStoreBadge} from "../SvgImages/AndroidStoreBadge/AndroidStoreBadge";
import {FaInstagram, FaFacebook, FaTwitter, FaLinkedin} from "react-icons/fa";

const Footer: React.FC = () => {
  const handleRedirect = (type: string) => {
    if (type === "android") {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.fluxlabs.zoop";
    } else {
      window.location.href =
        "https://apps.apple.com/us/app/zoop-discover-and-shop-live/id6670531808";
    }
  };

  return (
    <footer className="footer">
      <div className="footer__top">
        <div className="footer__logo-links">
          <div className="footer__logo">
            {}
            <img
              src={
                "https://storage.googleapis.com/zoop-public-bucket/web-zoop-website/zoop-logo-gif.gif"
              }
              alt="Company Logo"
              className="footer__logo-img"
            />
          </div>
          <ul className="footer__links">
            <li
              style={{cursor: "pointer"}}
              onClick={() => {
                window.location.href = "https://seller.zooplive.com/about/";
              }}>
              About
            </li>
            <li
              style={{cursor: "pointer"}}
              onClick={() => {
                window.location.href = "https://seller.zooplive.com/blog/";
              }}>
              Blog
            </li>
            <li>Careers</li>
            <li
              style={{cursor: "pointer"}}
              onClick={() => {
                window.location.href = "https://seller.zooplive.com/";
              }}>
              Become a seller
            </li>
            <li
              style={{cursor: "pointer"}}
              onClick={() => {
                window.location.href = "https://seller.zooplive.com/contact/";
              }}>
              Contact us
            </li>
            <li
              style={{cursor: "pointer"}}
              onClick={() => {
                window.location.href =
                  "https://seller.zooplive.com/buyerprotectionpolicy/";
              }}>
              Buyer Protection policy
            </li>
            <li
              style={{cursor: "pointer"}}
              onClick={() => {
                window.location.href =
                  "https://storage.googleapis.com/zoop-public-bucket/Shipping%2C%20Exchange%2C%20refunds%20and%20Returns%20Policy.docx%2030%20Oct.pdf";
              }}>
              Shipping and Return Policies
            </li>
          </ul>
        </div>
        <div className="footer__download">
          <span>Download the app</span>
          <div className="footer__download-buttons">
            <span
              onClick={() => {
                handleRedirect("ios");
              }}>
              <AppleStoreBadge />
            </span>

            <span
              onClick={() => {
                handleRedirect("android");
              }}>
              <AndroidStoreBadge />
            </span>
          </div>
        </div>
      </div>
      <div className="footer__bottom">
        <div className="footer__social">
          <FaFacebook size={20} style={{marginRight: "10px"}} />
          <FaTwitter size={20} style={{marginRight: "10px"}} />

          <FaInstagram
            size={20}
            style={{marginRight: "10px", cursor: "pointer"}}
            onClick={() => {
              window.location.href =
                "https://www.instagram.com/zoop.live?igsh=MW55aDlrejh1MGlreg==";
            }}
          />

          <FaLinkedin
            size={20}
            style={{marginRight: "10px", cursor: "pointer"}}
            onClick={() => {
              window.location.href =
                "https://www.linkedin.com/company/zoop-live/";
            }}
          />
        </div>
        <div className="footer__legal">
          <p>© 2024 Flux Labs AI Private Limited.</p>
          <p>
            <a href="https://storage.googleapis.com/zoop-public-bucket/Terms%20of%20Use-Buyer%20App%2016July.pdf">
              Terms of Service
            </a>{" "}
            |{" "}
            <a href="https://seller.zooplive.com/privacy-policy/">
              Privacy Policy
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
