import React, {useEffect, useState} from "react";
import {setAuthentication} from "../../../features/Authentication/AuthenticationSlice";
import cookieUtils from "../../../utils/cookies";
import {AppDispatch, RootState} from "../../../interceptors/store";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router";
import {getOrderByOrderIdThunk} from "../../../features/Order/OrderSlice";
// import OrderDetailsCard from './components/OrderDetailsCard/OrderDetailsCard';
import ProductCard from "./components/ProductCard/ProductCard";
import {OrderItem} from "../../../features/Order/OrderInterface";
import OrderDetailsCard from "./components/OrderDetailsCard/OrderDetailsCard";
import OrderStatusCard from "./components/OrderStatusCard/OrderStatusCard";
import "./OrderDetailsPage.style.css";
import OrderDetailsPageSkeleton from "./components/Skeleton/OrderDetailsPageSkeleton";
import OrderDetailsSelfShip from "./components/OrderDetailsSelfShip/OrderDetailsSelfShip";
import {apiConfig} from "../../../interceptors/api";

const OrderDetailsPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [downloadLink, setDownloadLink] = useState<string>("");

  const {orderId} = useParams<{orderId: string}>(); // Get the `orderId` from the URL
  const {orderItems} = useSelector((state: RootState) => state.order);
  const {data} = useSelector((state: RootState) => state.order.order);

  useEffect(() => {
    if (cookieUtils.getCookie("token")) {
      dispatch(setAuthentication(true));
    } else {
      navigate("/");
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    if (orderId) {
      getOrderDetails();
    }
  }, [dispatch, orderId, orderItems]);

  const getOrderDetails = async () => {
    setLoading(true);
    await dispatch(getOrderByOrderIdThunk({orderId: orderId ?? ""})); // Fetch order details
    setLoading(false);
  };

  const getBase64Url = async () => {
    const invoiceURL = data?.self_shipping_details?.invoice_url;
    try {
      const response = await fetch(
        `${apiConfig.inventoryUrl}/api/inventory/url-encoded`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({invoiceUrl: invoiceURL, outputType: "base64"}),
        },
      );

      if (!response.ok) {
        throw new Error("Failed to fetch invoice");
      }

      const result = await response.json();
      setDownloadLink(result.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadInvoice = async () => {
    getBase64Url();

    const handleDownload = async (downloadLink: string) => {
      // Create a programmatic anchor element
      const filename = `${data?.self_shipping_details?.id}.pdf`; // Use the ID as the filename with .pdf extension

      try {
        const byteCharacters = atob(downloadLink);

        const byteArrays = new Uint8Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
          byteArrays[i] = byteCharacters.charCodeAt(i);
        }

        const blob = new Blob([byteArrays], {type: "application/octet-stream"});
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob); // Create a temporary URL for the Blob
        link.setAttribute("download", filename);
        link.click();
      } catch (error) {
        console.error("Error downloading the file:", error);
        alert(
          "An error occurred while trying to download the file. Please try again.",
        );
      }
    };

    handleDownload(downloadLink);
  };

  return (
    <div className="order-details-page-container">
      <div className="order-details-heading">Order details</div>
      {/* {Array.from({length: 1}).map((_, index) => (
          <OrderDetailsPageSkeleton key={`stream-${index}`} />
        ))} */}
      {loading ? (
        // Skeleton loader while loading the orders
        Array.from({length: 5}).map((_, index) => (
          <OrderDetailsPageSkeleton key={`stream-${index}`} />
        ))
      ) : (
        <div>
          <OrderStatusCard
            orderItemCount={data?.order_item_details.length}
            orderNum={data?.order_id}
            buyerDetails={data?.buyer_details}
            currentStatus={data?.status}
            selfShipDetails={!!data?.self_shipping_details}
          />
          {data?.self_shipping_details && (
            <div className="order-status-selfship-card">
              <OrderDetailsSelfShip />
            </div>
          )}
          <div className="order-details-page-product-card-ctn">
            {data?.order_item_details.map((item: OrderItem, index: number) => (
              <ProductCard
                key={index}
                productName={item.product_name}
                productDescription={item.product_description}
                productQuantity={item.quantity}
                productImage={
                  item?.product_image_details?.[0] ||
                  "https://www.shutterstock.com/image-photo/head-shot-portrait-close-smiling-260nw-1714666150.jpg"
                }
                variants={item.variants}
                product_id={item.product_id}
              />
            ))}
          </div>

          <OrderDetailsCard
            totalItems={data?.order_item_details.length}
            priceIncludingGST={data?.total_item_amount}
            discount={
              (data?.total_item_amount ?? 0) - (data?.discounted_amount ?? 0)
            }
            deliveryCharge={data?.delivery_charges}
            orderTotal={data?.net_amount}
          />
          {data?.self_shipping_details && (
            <button
              className="download-invoice-button"
              onClick={handleDownloadInvoice}>
              Download Invoice
            </button>
          )}
          <a
            className="orderdetailspage-alert-header-container"
            href="https://seller.zooplive.com/contact/"
            target="_blank"
            rel="noopener noreferrer" // For security, when using target="_blank"
          >
            <div className="orderdetailspage-alert-header">
              If you have any queries, please contact us here
            </div>
          </a>
        </div>
      )}
    </div>
  );
};

export default OrderDetailsPage;
