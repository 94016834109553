import React from "react";

export interface CloseButtonSvgProps {
  height: number | string;
  width: number | string;
  color?: string;
}

const CloseButtonSvg: React.FC<CloseButtonSvgProps> = ({height, width}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 13.0634L8.75383 16.3096C8.60896 16.4545 8.43332 16.5253 8.2269 16.5221C8.0205 16.5189 7.84487 16.4448 7.7 16.3C7.55514 16.1551 7.4827 15.9779 7.4827 15.7682C7.4827 15.5586 7.55514 15.3814 7.7 15.2365L10.9366 12L7.69038 8.7788C7.54551 8.63393 7.47469 8.45669 7.4779 8.24707C7.4811 8.03746 7.55514 7.86021 7.7 7.71535C7.84487 7.57048 8.02211 7.49805 8.23173 7.49805C8.44133 7.49805 8.61856 7.57048 8.76343 7.71535L12 10.9615L15.2212 7.71535C15.366 7.57048 15.5417 7.49805 15.7481 7.49805C15.9545 7.49805 16.1301 7.57048 16.275 7.71535C16.4301 7.87048 16.5077 8.05029 16.5077 8.25477C16.5077 8.45926 16.4301 8.63393 16.275 8.7788L13.0384 12L16.2846 15.2461C16.4295 15.391 16.5019 15.5667 16.5019 15.7731C16.5019 15.9795 16.4295 16.1551 16.2846 16.3C16.1295 16.4551 15.9497 16.5326 15.7452 16.5326C15.5407 16.5326 15.366 16.4551 15.2212 16.3L12 13.0634Z"
        fill="#737475"
      />
    </svg>
  );
};

export default CloseButtonSvg;
