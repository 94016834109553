import React from "react";

export interface ContentCopyProps {
  height: number | string;
  width: number | string;
  color?: string;
}

export const ContentCopy = function ContentCopy(props: ContentCopyProps) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.05775 15.5C5.55258 15.5 5.125 15.325 4.775 14.975C4.425 14.625 4.25 14.1974 4.25 13.6923V2.30775C4.25 1.80258 4.425 1.375 4.775 1.025C5.125 0.675 5.55258 0.5 6.05775 0.5H14.4423C14.9474 0.5 15.375 0.675 15.725 1.025C16.075 1.375 16.25 1.80258 16.25 2.30775V13.6923C16.25 14.1974 16.075 14.625 15.725 14.975C15.375 15.325 14.9474 15.5 14.4423 15.5H6.05775ZM6.05775 14H14.4423C14.5192 14 14.5898 13.9679 14.6538 13.9038C14.7179 13.8398 14.75 13.7692 14.75 13.6923V2.30775C14.75 2.23075 14.7179 2.16025 14.6538 2.09625C14.5898 2.03208 14.5192 2 14.4423 2H6.05775C5.98075 2 5.91025 2.03208 5.84625 2.09625C5.78208 2.16025 5.75 2.23075 5.75 2.30775V13.6923C5.75 13.7692 5.78208 13.8398 5.84625 13.9038C5.91025 13.9679 5.98075 14 6.05775 14ZM2.55775 19C2.05258 19 1.625 18.825 1.275 18.475C0.925 18.125 0.75 17.6974 0.75 17.1923V5.05775C0.75 4.84492 0.821833 4.66667 0.9655 4.523C1.109 4.3795 1.28717 4.30775 1.5 4.30775C1.71283 4.30775 1.89108 4.3795 2.03475 4.523C2.17825 4.66667 2.25 4.84492 2.25 5.05775V17.1923C2.25 17.2693 2.28208 17.3398 2.34625 17.4038C2.41025 17.4679 2.48075 17.5 2.55775 17.5H11.6923C11.9051 17.5 12.0833 17.5718 12.227 17.7153C12.3705 17.8589 12.4423 18.0372 12.4423 18.25C12.4423 18.4628 12.3705 18.641 12.227 18.7845C12.0833 18.9282 11.9051 19 11.6923 19H2.55775Z"
        fill="black"
      />
    </svg>
  );
};
