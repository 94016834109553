import {useEffect, useState} from "react";

export const useStorefrontDetection = (): boolean => {
  const [isStoreFront, setIsStoreFront] = useState(false);

  useEffect(() => {
    const hostname = window.location.hostname;

    const subdomain = hostname.split(".")[0];
    // Subdomain condition, change accordingly if needed
    if (hostname === "zooplive.com" || hostname === "www.zooplive.com") {
      setIsStoreFront(false);
    } else if (
      subdomain &&
      subdomain !== "localhost" &&
      subdomain !== "web-uat"
    ) {
      setIsStoreFront(true);
    }
  }, []);

  return isStoreFront;
};
