import React from "react";
import "./OrderDetailsCard.css";
import {convertIntoINRV2} from "../../utils/helpers";
// Define the types for the props
interface OrderDetailsCardProps {
  totalItems: number;
  priceIncludingGST: number;
  discount: number;
  deliveryCharge: number | null | undefined;
  orderTotal: number;
  // originalTotalAmount : number
  discountPrice: number;
  couponDiscount?: number;
}

const OrderDetailsCard: React.FC<OrderDetailsCardProps> = ({
  totalItems,
  priceIncludingGST,
  discount,
  deliveryCharge,
  orderTotal,
  couponDiscount,
}) => {
  return (
    <div className="orderDetailsContainer">
      <div className="orderDetailsHeadingContainer">
        <span className="orderDetailsHeadingText">Order details</span>
      </div>
      <div className="orderDetailsBodyContainer">
        <div className="orderDetailsRowContainer">
          <div className="orderDetailsRowLeftContainer">
            <span className="orderDetailsLeftText">Items</span>
          </div>
          <div className="orderDetailsRowRightContainer">
            <span className="orderDetailsRightText">{totalItems}</span>
          </div>
        </div>
        <div className="orderDetailsRowContainer">
          <div className="orderDetailsRowLeftContainer">
            <span className="orderDetailsLeftText">Price</span>
          </div>
          <div className="orderDetailsRowRightContainer">
            <span className="orderDetailsRightText">
              {convertIntoINRV2(priceIncludingGST.toString())}
            </span>
          </div>
        </div>
        {discount ? (
          <div className="orderDetailsRowContainer">
            <div className="orderDetailsRowLeftContainer">
              <span className="orderDetailsLeftText">Discount</span>
            </div>
            <div className="orderDetailsRowRightContainer">
              <span className="orderDetailsRightText  freeText">
                - {convertIntoINRV2(discount.toString())}
              </span>
            </div>
          </div>
        ) : null}
        {couponDiscount ? (
          <div className="orderDetailsRowContainer">
            <div className="orderDetailsRowLeftContainer">
              <span className="orderDetailsLeftText">Coupon Discount</span>
            </div>
            <div className="orderDetailsRowRightContainer">
              <span className="orderDetailsRightText  freeText">
                - {convertIntoINRV2(couponDiscount.toString())}
              </span>
            </div>
          </div>
        ) : null}

        <div className="orderDetailsRowContainer">
          <div className="orderDetailsRowLeftContainer">
            <span className="orderDetailsLeftText">Delivery charge</span>
          </div>
          <div className="orderDetailsRowRightContainer">
            <span
              className={`orderDetailsRightText ${deliveryCharge === 0 ? "freeText" : ""}`}>
              {deliveryCharge === 0
                ? "Free"
                : convertIntoINRV2(deliveryCharge?.toString() || "")}
            </span>
          </div>
        </div>
        <div className="orderDetailsRowContainer orderTotalRow">
          <div className="orderDetailsRowLeftContainer">
            <span className="orderTotalHeading">Order Total</span>
          </div>
          <div className="orderDetailsRowRightContainer">
            <span className="orderTotalText">
              {convertIntoINRV2(orderTotal.toString())}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailsCard;
