/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {FC, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {
  getCategoriesThunk,
  getSubCategoriesThunk,
} from "../../../features/User/UserSlice";
import {AppDispatch, RootState} from "../../../interceptors/store";
import {CategoryProducts} from "./components/CategoryProducts/CategoryProducts";
import {SortBy} from "./components/SortBy/SortBy";
import {FilterBy} from "./components/FilterBy/FilterBy";
import FilterSvg from "../../../components/SvgImages/FilterSvg";
import "./ProductsPage.style.css";
import {useUrlParams} from "../../../hooks/useURLParams";
import useMobileSize from "../../../hooks/useMobileSize";
import {FilterByDesktop} from "./components/FilterByDeskstop/FilterByDesktop";
import DropdownOptionDivider from "../../../components/SvgImages/DropdownOptionDivider/DropdownOptionDivider";
import SortByArrowSvg from "../../../components/SvgImages/SortByArrowSvg/SortByArrowSvg";
// Interfaces
export interface SelectedEntity {
  id: number;
  name: string;
}

export interface SelectedFilters {
  selectedCategories: SelectedEntity[];
  selectedSubCategories: SelectedEntity[];
}

const ProductsPage: FC = () => {
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const params = location.state || {};
  const {categories} = useSelector((state: RootState) => state.user);

  const [showSortBySheet, setShowSortBySheet] = useState(false);
  const [showFilterBySheet, setShowFilterBySheet] = useState(false);
  const [selectedSortOption, setSelectedSortOption] = useState<string>("");

  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({
    selectedCategories: [],
    selectedSubCategories: [],
  });
  const [categoryIds, setCategoryIds] = useState<string | undefined>(
    params.categoryId,
  );
  const [subCategoryIds, setSubCategoryIds] = useState<string | undefined>(
    params.subCategoryId,
  );
  const [filterData, setFilterData] = useState<any[]>([]);
  const [header, setHeader] = useState<string>(params.categoryName);
  const [selectedFilterOption, setSelectedFilterOption] =
    useState<string>("Categories");
  // const urlParams = new URLSearchParams(window.location.search);
  const isMobile = useMobileSize();

  useEffect(() => {
    // Event listener for back/forward navigation
    const handleBackNavigation = () => {
      // Force component refresh or trigger re-fetching logic
      window.location.reload();
    };

    // Add event listener for popstate (back/forward button)
    window.addEventListener("popstate", handleBackNavigation);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("popstate", handleBackNavigation);
    };
  }, []);

  useUrlParams(
    setCategoryIds,
    setSubCategoryIds,
    setSelectedFilters,
    setSelectedSortOption,
  );

  const updateUrl = () => {
    // Set to remove duplicate ids
    const categoriesParam = [
      ...new Set(
        selectedFilters.selectedCategories.map(category => category.id),
      ),
    ].join(",");

    const subCategoriesParam = [
      ...new Set(
        selectedFilters.selectedSubCategories.map(
          subcategory => subcategory.id,
        ),
      ),
    ].join(",");

    const sortParam = selectedSortOption
      ? selectedSortOption.toLowerCase().replace(" ", "-")
      : "";

    // Build the URL based on the existence of parameters
    let newUrl = "/products";

    const queryParams = [];

    if (categoriesParam) {
      queryParams.push(`categories=${categoriesParam}`);
    }
    if (subCategoriesParam) {
      queryParams.push(`subcategories=${subCategoriesParam}`);
    }
    if (sortParam) {
      queryParams.push(`sort=${sortParam}`);
    }

    if (queryParams.length > 0) {
      newUrl += `?${queryParams.join("&")}`;
    }

    window.history.pushState({}, "", newUrl);
  };

  useEffect(() => {
    updateUrl();
  }, [selectedSortOption, selectedFilters]);

  useEffect(() => {
    const getCategories = async () => {
      try {
        await dispatch(getCategoriesThunk());
      } catch (error) {
        console.log("error", error);
      }
    };

    getCategories();
  }, []);

  // Fetch subcategories based on categoryId
  const getSubCategories = async (categoryId: number) => {
    try {
      const action = await dispatch(getSubCategoriesThunk(categoryId));
      if (getSubCategoriesThunk.fulfilled.match(action)) {
        return action.payload.Sub_category;
      }
      return [];
    } catch (error) {
      console.error("Error fetching subcategories:", error);
      return [];
    }
  };

  // Fetch filter data based on selected options
  useEffect(() => {
    const fetchFilterData = async () => {
      if (selectedFilterOption === "Categories") {
        setFilterData(categories);
      } else if (
        selectedFilterOption === "Sub-categories" &&
        selectedFilters?.selectedCategories.length
      ) {
        const subCategoryPromises = selectedFilters.selectedCategories.map(
          category => getSubCategories(category.id),
        );
        const subCategoriesArray = await Promise.all(subCategoryPromises);
        const allSubCategories = subCategoriesArray.flat();
        setFilterData(allSubCategories);
      } else {
        setFilterData([]);
      }
    };
    fetchFilterData();
  }, [selectedFilterOption, selectedFilters?.selectedCategories]);

  // Handle filter and sort option changes
  const handleApplyFilters = () => {
    setCategoryIds(
      selectedFilters.selectedCategories.map(filter => filter.id).join(","),
    );
    setSubCategoryIds(
      selectedFilters.selectedSubCategories.map(filter => filter.id).join(","),
    );
    setShowFilterBySheet(false);
    setHeader("Filtered Products");
    updateUrl();
  };

  const handleCategoryChange = () => {
    setSelectedFilters(prev => ({
      selectedCategories: [...prev.selectedCategories], // Spread the previous selected categories
      selectedSubCategories: [], // Clear selected subcategories
    }));
  };

  const handleClearAllFilters = () => {
    setCategoryIds(undefined);
    setSubCategoryIds(undefined);
    setSelectedFilters({
      selectedCategories: [],
      selectedSubCategories: [],
    });
    setShowFilterBySheet(false);
    updateUrl();
  };
  const handleSortOptionChange = (option: string) => {
    setSelectedSortOption(option);
    setShowSortBySheet(false);
    updateUrl();
  };
  const handleResetSortOption = () => {
    setSelectedSortOption(""); // Reset selected sort option
    setShowSortBySheet(false); // Close the sort by sheet
    updateUrl(); // Update the URL or any other side effect
  };

  return (
    <div className="products-page-container">
      <div className="products-page-container-top">
        <div className="products-page-header">{header || "Products"}</div>
        <div className="products-page-dropdown-container">
          <div className="products-page-dropdown">
            <div className="products-page-dropdown-sortby-container">
              <button
                className={`products-page-dropdown-button 
                  ${showSortBySheet ? "active" : ""}
                   ${selectedSortOption ? "selectedSort" : ""}
                   `}
                onClick={() => {
                  setShowSortBySheet(!showSortBySheet);
                  setShowFilterBySheet(false);
                }}>
                <SortByArrowSvg width="24" height="24" />
                <div className={`products-page-dropdown-text`}>
                  <span className="products-page-dropdown-text-heading">
                    {selectedSortOption ? selectedSortOption : "Sort By"}
                  </span>
                </div>
                {selectedSortOption && (
                  <div className="products-page-dropdown-selected-option"></div>
                )}
              </button>
              <div className="products-page-sortby-container">
                <SortBy
                  sortOptions={["Price Low to High", "Price High to Low"]}
                  selectedSortOption={selectedSortOption}
                  show={showSortBySheet}
                  onClose={() => setShowSortBySheet(false)}
                  onSortOptionChange={handleSortOptionChange}
                  handleResetSortOption={handleResetSortOption}
                />
              </div>
            </div>
            <div className="products-page-dropdown-divider">
              <DropdownOptionDivider width={"4px"} height={"32px"} />
            </div>

            <div className="products-page-dropdown-filterby-container">
              <button
                className={`products-page-dropdown-button ${showFilterBySheet ? "active" : ""}`}
                onClick={() => {
                  setShowFilterBySheet(!showFilterBySheet);
                  setShowSortBySheet(false);
                }}>
                <FilterSvg width="20" height="20" />
                <div className="products-page-dropdown-text">
                  <span className="products-page-dropdown-text-heading">
                    Filter
                  </span>
                </div>
                {(selectedFilters.selectedCategories.length > 0 ||
                  selectedFilters.selectedSubCategories.length > 0) && (
                  <div className="products-page-dropdown-selected-option"></div>
                )}
              </button>
              <div className="products-page-filterby-container">
                {isMobile ? (
                  <FilterBy
                    filterOptions={["Categories", "Sub-categories"]}
                    selectedFilterOption={selectedFilterOption}
                    selectedFilters={selectedFilters}
                    filterData={filterData}
                    show={showFilterBySheet}
                    onFilterOptionChange={(option: any) => {
                      setSelectedFilterOption(option);
                    }}
                    onAddFilters={(option: any) => {
                      setSelectedFilters(prev => ({
                        ...prev,
                        selectedCategories:
                          selectedFilterOption === "Categories"
                            ? [...prev.selectedCategories, option]
                            : prev.selectedCategories,
                        selectedSubCategories:
                          selectedFilterOption === "Sub-categories"
                            ? [...prev.selectedSubCategories, option]
                            : prev.selectedSubCategories,
                      }));
                    }}
                    onRemoveFilter={(option: any) => {
                      setSelectedFilters(prev => ({
                        ...prev,
                        selectedCategories:
                          selectedFilterOption === "Categories"
                            ? prev.selectedCategories.filter(
                                item => item.id !== option.id,
                              )
                            : prev.selectedCategories,
                        selectedSubCategories:
                          selectedFilterOption === "Sub-categories"
                            ? prev.selectedSubCategories.filter(
                                item => item.id !== option.id,
                              )
                            : prev.selectedSubCategories,
                      }));
                    }}
                    onClearFilters={handleClearAllFilters}
                    onApplyFilters={handleApplyFilters}
                    onClose={() => setShowFilterBySheet(false)}
                  />
                ) : (
                  <FilterByDesktop
                    isOpen={showFilterBySheet}
                    onClearFilters={handleClearAllFilters}
                    onApplyFilters={handleApplyFilters}
                    selectedFilters={selectedFilters}
                    selectedFilterOption={selectedFilterOption}
                    handleCategoryChange={handleCategoryChange}
                    onFilterOptionChange={(option: any) => {
                      setSelectedFilterOption(option);
                    }}
                    onAddFilters={(option: any) => {
                      setSelectedFilters(prev => ({
                        ...prev,
                        selectedCategories:
                          selectedFilterOption === "Categories"
                            ? [option]
                            : prev.selectedCategories,
                        selectedSubCategories:
                          selectedFilterOption === "Sub-categories"
                            ? [...prev.selectedSubCategories, option]
                            : prev.selectedSubCategories,
                      }));
                    }}
                    onRemoveFilter={(option: any) => {
                      setSelectedFilters(prev => ({
                        ...prev,
                        selectedCategories:
                          selectedFilterOption === "Categories"
                            ? prev.selectedCategories.filter(
                                item => item.id !== option.id,
                              )
                            : prev.selectedCategories,
                        selectedSubCategories:
                          selectedFilterOption === "Sub-categories"
                            ? prev.selectedSubCategories.filter(
                                item => item.id !== option.id,
                              )
                            : prev.selectedSubCategories,
                      }));
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <SortBy
        sortOptions={["Price Low to High", "Price High to Low"]}
        selectedSortOption={selectedSortOption}
        show={showSortBySheet}
        onClose={() => setShowSortBySheet(false)}
        onSortOptionChange={handleSortOptionChange}
      /> */}

      <div className="products-page-products">
        <CategoryProducts
          categoryId={categoryIds}
          subCategoryId={subCategoryIds}
          selectedSortOption={selectedSortOption}
          discount={params.discount}
          selectedFilters={selectedFilters}
        />
      </div>
    </div>
  );
};

export default ProductsPage;
