import React, {useEffect, useState} from "react";
import "./FilterByDesktop.css";
import {Category} from "../../../../../features/User/UserInterface";
import {AppDispatch} from "../../../../../interceptors/store";
import {useDispatch} from "react-redux";
import {getCategoriesThunk} from "../../../../../features/User/UserSlice";
import {SelectedEntity, SelectedFilters} from "../../ProductsPage";
import GreaterThanSvg from "../../../../../components/SvgImages/GreaterThanSvg/GreaterThanSvg";
import LessThanSvg from "../../../../../components/SvgImages/LessThanSvg/LessThanSvg";

interface CustomButtonProps {
  onPress: () => void;
  title: string;
  buttonContainerStyle?: React.CSSProperties;
  buttonTextStyle?: React.CSSProperties;
  disabled?: boolean;
  loading?: boolean;
  blueIndicatorColor?: boolean;
  activeOpacity?: number;
  className?: string; // Added className here
}

interface FilterByDeskstopProps {
  isOpen: boolean;
  onApplyFilters: () => void;
  onClearFilters: () => void;
  selectedFilters: SelectedFilters | undefined;
  onAddFilters: (value: SelectedEntity) => void;
  onRemoveFilter: (value: SelectedEntity) => void;
  onFilterOptionChange: (value: string) => void;
  selectedFilterOption: string | undefined;
  handleCategoryChange: () => void;
}

interface CheckableListItemProps {
  title: string;
  selected: boolean | undefined;
  onPress: () => void;
}

export const FilterByDesktop: React.FC<FilterByDeskstopProps> = ({
  isOpen,
  onClearFilters,
  onApplyFilters,
  selectedFilters,
  onAddFilters,
  onRemoveFilter,
  handleCategoryChange,
  onFilterOptionChange,
  selectedFilterOption,
}) => {
  const [isVisible, setIsVisible] = useState(isOpen);
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null,
  );
  const [transitioning, setTransitioning] = useState(false); // Track transition state
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch<AppDispatch>();

  // Toggle visibility of the modal based on `isOpen`
  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
    } else {
      const timer = setTimeout(() => setIsVisible(false), 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  // Fetch categories from the server
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const action = await dispatch(getCategoriesThunk());
        if (getCategoriesThunk.fulfilled.match(action)) {
          setCategories(action.payload.categories);
        } else {
          setError("Failed to fetch categories");
        }
      } catch (err) {
        console.error("Error fetching categories:", err);
        setError("An unexpected error occurred while fetching categories");
      }
    };

    fetchCategories();
  }, [dispatch]);

  // Handle category selection and transitioning to sub-categories
  const handleCategoryClick = (category: Category) => {
    if (selectedFilters) {
      handleCategoryChange();
    }
    onFilterOptionChange("Sub-categories");

    onAddFilters({id: category.id, name: category.name});

    if (category.subCategory && category.subCategory.length > 0) {
      setTransitioning(true);
      setTimeout(() => {
        setSelectedCategory(category);
        setTransitioning(false);
      }, 300); // Duration of the slide-out animation
    }
  };

  // Handle back button click
  const handleBackClick = () => {
    onFilterOptionChange("Categories");
    setTransitioning(true);
    setTimeout(() => {
      setSelectedCategory(null);
      setTransitioning(false);
    }, 300); // Duration of the slide-in animation
  };

  if (!isVisible) return null;

  // Function to check if an option is selected
  const isSelected = (option: SelectedEntity) => {
    if (selectedFilterOption === "Categories") {
      const result = selectedFilters?.selectedCategories?.some(
        filter => filter.id === option.id,
      );
      return result;
    } else if (selectedFilterOption === "Sub-categories") {
      const result = selectedFilters?.selectedSubCategories?.some(
        filter => filter.id === option.id,
      );
      return result;
    }
    return false;
  };

  // CustomButton component for reusable buttons
  const CustomButton: React.FC<CustomButtonProps> = ({
    onPress,
    title,
    buttonContainerStyle = {},
    buttonTextStyle = {},
    loading = false,
    disabled = false,
    blueIndicatorColor = false,
    className = "",
  }) => {
    const handleClick = () => {
      onPress();
    };

    return (
      <button
        onClick={handleClick}
        disabled={loading || disabled}
        className={`custom-button ${className} ${
          disabled || loading ? "button-disabled" : ""
        }`}
        style={buttonContainerStyle}
        aria-disabled={disabled || loading ? "true" : "false"}>
        {loading ? (
          <div
            className={`button-loading ${blueIndicatorColor ? "blue" : ""}`}></div>
        ) : (
          <span className="button-text" style={buttonTextStyle}>
            {title}
          </span>
        )}
      </button>
    );
  };

  // Checkable list item that allows selecting items
  const CheckableListItem = ({
    title,
    selected,
    onPress,
  }: CheckableListItemProps) => {
    return (
      <div className="filter-by-category-checkable-item" onClick={onPress}>
        <span className="filter-by-category-item-text">{title}</span>
        <input
          type="checkbox"
          checked={selected}
          onChange={() => {
            onPress();
          }}
          className="filter-by-category-check-box"
        />
      </div>
    );
  };

  return (
    <div className={`filterby-desktop-category-popup-content`}>
      <div className="filterby-desktop-category-popup-header">
        {selectedCategory && (
          <>
            <button
              className="filter-category-back-button"
              onClick={handleBackClick}>
              <div className="filter-category-back-button-svg">
                <LessThanSvg width={"24px"} height={"24px"} color="#737475" />
              </div>
              <div className="filter-category-back-button-text">Back</div>
            </button>
          </>
        )}
      </div>

      {error ? (
        <p className="category-popup-error">{error}</p>
      ) : (
        <div
          className={`filter-by-category-popup-list-wrapper ${
            transitioning ? (selectedCategory ? "slide-out" : "slide-in") : ""
          }`}>
          <ul className="filter-by-category-popup-list">
            {selectedCategory && selectedCategory.subCategory.length > 0 && (
              <>
                {/* "Select All" Option */}
                <CheckableListItem
                  key="select-all"
                  title={`All ${selectedCategory.name}`} // Title for "Select All"
                  selected={selectedCategory.subCategory.every(isSelected)}
                  onPress={() => {
                    // Handle selecting/deselecting all subcategories
                    if (selectedCategory.subCategory.every(isSelected)) {
                      // If all subcategories are selected, remove all
                      selectedCategory.subCategory.forEach(onRemoveFilter);
                    } else {
                      // Otherwise, add all subcategories
                      selectedCategory.subCategory.forEach(onAddFilters);
                    }
                  }}
                />

                {/* Render all the subcategories */}
                {selectedCategory.subCategory.map((sub, index) => {
                  return (
                    <CheckableListItem
                      key={`${sub.id}-${index}`}
                      title={sub.name}
                      selected={isSelected(sub)}
                      onPress={() => {
                        if (isSelected(sub)) {
                          onRemoveFilter(sub);
                        } else {
                          onAddFilters(sub);
                        }
                      }}
                    />
                  );
                })}
              </>
            )}
            {/* Render categories if no category is selected */}
            {!selectedCategory &&
              categories.map((category, index) => (
                <li
                  key={index}
                  className="filter-by-category-popup-item"
                  onClick={() => handleCategoryClick(category)}>
                  <span
                    className={`filter-by-item-text ${isSelected(category) ? "selected" : ""}`}>
                    {category.name}
                  </span>
                  <span className="filter-by-item-symbol-greterThan">
                    {!isSelected(category) ? (
                      <GreaterThanSvg
                        width={"24px"}
                        height={"24px"}
                        color="#737475"
                      />
                    ) : (
                      <GreaterThanSvg
                        width={"24px"}
                        height={"24px"}
                        color="#207A9C"
                      />
                    )}
                  </span>
                </li>
              ))}
          </ul>
        </div>
      )}

      <div className="filter-footer">
        <div className="filter-footer-buttons">
          <CustomButton
            title={"Clear Filter"}
            onPress={() => {
              onClearFilters();
              setSelectedCategory(null);
            }}
            className={"filter-left-button"}
            buttonTextStyle={{color: "#1E799C"}} // Active text style
          />
          <CustomButton
            title={"Apply Filters"}
            onPress={() => {
              onApplyFilters();
            }}
            className="filter-right-button"
            buttonTextStyle={{color: "#ffffff"}} // Active text style for Apply button
            disabled={
              selectedFilters?.selectedCategories.length === 0 &&
              selectedFilters.selectedSubCategories.length === 0
            }
          />
        </div>
      </div>
    </div>
  );
};
