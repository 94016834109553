import React, {useState} from "react";
import "./ReviewOverlay.style.css";
import BottomSheet from "../../../../../components/mobile/BottomSheet/BottomSheet";
import {Review} from "../../../../../features/User/UserInterface";
import {renderStars, timeAgo} from "../../../../../utils/helpers";

interface ReviewOverlayProps {
  isOpen: boolean;
  onClose: (value: boolean) => void;
  reviews: Review[];
  reviewCount: number;
  setCurrentImage: (image: string | null) => void;
}

const ReviewOverlay: React.FC<ReviewOverlayProps> = ({
  isOpen,
  onClose,
  reviews,
  reviewCount,
  setCurrentImage,
}) => {
  const [expandedReview, setExpandedReview] = useState<number | null>(null);
  const toggleShowAllPhotos = (index: number) => {
    setExpandedReview(prev => (prev === index ? null : index));
  };
  const handlePhotoClick = (photo: string) => {
    setCurrentImage(photo);
  };

  return (
    <BottomSheet
      header={`Reviews (${reviewCount})`}
      isOpen={isOpen}
      onClose={() => {
        onClose(isOpen);
      }}>
      <div className="storefront-review-overlay-exit-stream-content-container">
        <div className="storefront-review-overlay-review-list">
          {reviews.map((review, index) => (
            <div className="storefront-review-overlay-review-item" key={index}>
              <div className="storefront-review-overlay-review-header">
                {review.review_media && review.review_media.length > 0 && (
                  <div className="storefront-review-overlay-review-photos">
                    {/* Render the first row of photos */}
                    <div className="storefront-review-overlay-photo-row">
                      {review.review_media
                        .slice(0, 4)
                        .map((photo, photoIndex) => (
                          <div
                            key={photoIndex}
                            className="storefront-review-overlay-review-photo-wrapper">
                            <img
                              src={photo}
                              alt={`Review Photo ${photoIndex + 1}`}
                              className="storefront-review-overlay-review-photo"
                              onClick={() => {
                                handlePhotoClick(photo);
                              }}
                            />
                          </div>
                        ))}
                      {review.review_media.length > 4 &&
                        expandedReview !== index && (
                          <div
                            className="storefront-review-overlay-more-photos"
                            onClick={() => toggleShowAllPhotos(index)}>
                            +{review.review_media.length - 4}
                          </div>
                        )}
                    </div>

                    {/* Render additional photos in a new row if expanded */}
                    {expandedReview === index && (
                      <div className="storefront-review-overlay-photo-row">
                        {review.review_media
                          .slice(4)
                          .map((photo, photoIndex) => (
                            <div
                              key={photoIndex}
                              className="storefront-review-overlay-review-photo-wrapper">
                              <img
                                src={photo}
                                alt={`Review Photo ${photoIndex + 5}`}
                                className="storefront-review-overlay-review-photo"
                                onClick={() => {
                                  handlePhotoClick(photo);
                                }}
                              />
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                )}
                <div className="storefront-review-overlay-stars">
                  {renderStars(review.avg_rating)}
                  <span className="storefront-review-overlay-review-date">
                    {" "}
                    • {timeAgo(review.created_at)}
                    {/* <span className="storefront-review-overlay-verified">Verified customer</span> */}
                  </span>
                </div>
              </div>
              <p className="storefront-review-overlay-review-text">
                {review.review_text}
              </p>
              <span className="storefront-review-overlay-review-author">
                {review.name}
              </span>
            </div>
          ))}
        </div>
      </div>
    </BottomSheet>
  );
};

export default ReviewOverlay;
