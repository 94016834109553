import React from "react";
import {algoliasearch} from "algoliasearch";
import {SearchBar} from "../SearchBar/SearchBar";
import {Configure, InstantSearch} from "react-instantsearch";
import {matchPath, useLocation, useNavigate} from "react-router";

const client = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
  process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY,
);

interface SearchBarProps {
  placeholder?: string;
  onSearch?: (query: string) => void;
  searchPage: boolean;
}

export const AloliaSearch: React.FC<SearchBarProps> = ({searchPage}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleSearch = (query: string) => {
    navigate(`/search?query=${encodeURIComponent(query.trim())}`);
  };

  const renderMobileView = !["/", "/products", "/checkout"].some(path =>
    matchPath({path, end: true}, location.pathname),
  );

  return (
    <>
      <InstantSearch indexName="products" searchClient={client}>
        <Configure hitsPerPage={20} />
        <SearchBar
          onSearch={handleSearch}
          isVisible={renderMobileView}
          searchPage={searchPage}
        />
      </InstantSearch>
    </>
  );
};
