import React from "react";

export interface DropdownOptionDividerProps {
  height: number | string;
  width: number | string;
  color?: string;
}

const DropdownOptionDivider: React.FC<DropdownOptionDividerProps> = ({
  height,
  width,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 4 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" width="3" height="32" rx="1.5" fill="#E3E3E3" />
    </svg>
  );
};

export default DropdownOptionDivider;
