import React from "react";
import {useNavigate} from "react-router";
import SearchSvg from "../SvgImages/SearchSvg/SearchSvg";
import useMobileSize from "../../hooks/useMobileSize";
import {Dispatch, SetStateAction} from "react";

interface SearchBarProps {
  placeholder?: string;
  onSearch?: (query: string) => void;
  isVisible: boolean;
  setSearchPage: Dispatch<SetStateAction<boolean | false>>;
}

export const StaticSearchBar: React.FC<SearchBarProps> = ({
  placeholder = "Search 'Tshirt'",
  setSearchPage,
}) => {
  const navigate = useNavigate();

  const isMobile = useMobileSize(1000);

  const openModal = () => {
    navigate("/search");
    setSearchPage(true);
  };

  return (
    <>
      {isMobile ? (
        <div className="static-search-bar" onClick={openModal}>
          <span className="search-bar__icon">
            <SearchSvg height={30} width={30} color="#ffffff" />
          </span>
        </div>
      ) : (
        <div className="static-search-bar" onClick={openModal}>
          <span className="search-bar__icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="search-bar__svg">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-4.35-4.35m0 0A7.5 7.5 0 1 0 4.35 4.35a7.5 7.5 0 0 0 12.3 12.3z"
              />
            </svg>
          </span>
          <input
            onFocus={() => openModal()}
            type="text"
            className="search-bar__input"
            placeholder={placeholder}
          />
        </div>
      )}
    </>
  );
};
