import React, {useState} from "react";
import "./SortBy.style.css"; // Import external CSS file for styling
import CloseButtonSvg from "../../../../../components/SvgImages/CloseButtonSvg/CloseButtonSvg";

interface SortByProps {
  sortOptions: string[];
  selectedSortOption: string;
  show: boolean;
  onClose: () => void;
  onSortOptionChange: (option: string) => void;
  handleResetSortOption: () => void;
}

interface CustomButtonProps {
  onPress: () => void;
  title: string;
  buttonContainerStyle?: React.CSSProperties;
  buttonTextStyle?: React.CSSProperties;
  disabled?: boolean;
  loading?: boolean;
  blueIndicatorColor?: boolean;
  activeOpacity?: number;
  className?: string; // Added className here
}

interface CheckableListItemProps {
  title: string;
  selected: boolean | undefined;
  onPress: () => void;
}

export const SortBy = ({
  sortOptions,
  selectedSortOption,
  show,
  onClose,
  onSortOptionChange,
  handleResetSortOption,
}: SortByProps) => {
  const [tempSelectedOption, setTempSelectedOption] =
    useState<string>(selectedSortOption);

  // const handleOptionChange = (option: string) => {
  //   onSortOptionChange(option);
  // };

  const handleOptionChange = (option: string) => {
    // Update the temporary selected option when user selects an option
    setTempSelectedOption(option);
  };

  const handleApply = () => {
    // Apply the selected option by calling onSortOptionChange
    onSortOptionChange(tempSelectedOption);
  };

  const CheckableListItem = ({
    title,
    selected,
    onPress,
  }: CheckableListItemProps) => {
    return (
      <div className="sortby-checkable-item" onClick={onPress}>
        <span className="sortby-item-text">{title}</span>
        <input
          type="radio"
          checked={selected}
          onChange={onPress}
          className="sortby-radio"
        />
      </div>
    );
  };

  const CustomButton: React.FC<CustomButtonProps> = ({
    onPress,
    title,
    buttonContainerStyle = {},
    buttonTextStyle = {},
    loading = false,
    disabled = false,
    blueIndicatorColor = false,
    className = "",
  }) => {
    const handleClick = () => {
      onPress();
    };

    return (
      <button
        onClick={handleClick}
        disabled={loading || disabled}
        className={`custom-button ${className} ${disabled || loading ? "button-disabled" : ""}`}
        style={buttonContainerStyle}
        aria-disabled={disabled || loading ? "true" : "false"}>
        {loading ? (
          <div
            className={`button-loading ${blueIndicatorColor ? "blue" : ""}`}></div>
        ) : (
          <span className="button-text" style={buttonTextStyle}>
            {title}
          </span>
        )}
      </button>
    );
  };

  if (!show) return null; // Don't render anything if modal is not shown

  return (
    <div className="sort-by-modal">
      <div className="sort-by-content">
        <div className="sort-by-header">
          <span className="sort-by-title">Sort By</span>
          <div
            onClick={() => {
              onClose();
            }}>
            <CloseButtonSvg height={"26px"} width={"26px"} />
          </div>
        </div>
        <div className="sort-by-options-container">
          {sortOptions.map(option => (
            <CheckableListItem
              key={option}
              title={option}
              selected={tempSelectedOption === option} // Use tempSelectedOption
              onPress={() => handleOptionChange(option)} // Update selected option
            />
          ))}
        </div>
        <div className="sortby-footer">
          <div className="sortby-footer-buttons">
            <CustomButton
              title={"Reset"}
              onPress={handleResetSortOption}
              className={"sortby-left-button"}
              buttonTextStyle={{color: "#1E799C"}} // Active text style
            />
            <CustomButton
              title={"Apply"}
              onPress={handleApply} // Apply the selected sort option
              className="sortby-right-button"
              buttonTextStyle={{color: "#ffffff"}} // Active text style for Apply button
            />
          </div>
        </div>
      </div>
    </div>
  );
};
