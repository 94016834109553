import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import "./Cart.style.css";
import {AppDispatch, RootState} from "../../../interceptors/store";

///internal imports
import {SingleCart} from "../../../components/Cart/SingleCart";
import OrderDetailsCard from "../../../components/OrderDetails/OrderDetailsCard";
import DeliveryAddressCard from "../../../components/Cart/DeliveryAddressCart";
import {SelectAddressV2} from "../../../components/mobile/SelectAddressV2/SelectAddressV2";
import {
  getCartDetailsThunk,
  removeItemFromCartThunk,
  updateCartItemThunk,
} from "../../../features/Cart/CartSlice";
import {
  calculateDeliveryCharges,
  convertIntoINR,
  getAddressStringFromObject,
} from "../../../utils/helpers";
import {getStoreFrontDetailsThunk} from "../../../features/User/UserSlice";
import {
  CartItemDetails,
  UpdateCartPayload,
  GroupedStore,
} from "../../../features/Cart/CartInterface";
import {useLocation, useNavigate} from "react-router";
import {toast} from "react-toastify";
import Juspay from "../../../components/Juspay/Juspay";
import Loader from "../../../Loader/Loader";
import CustomLoader from "../../../components/CustomLoader/CustomLoader";
import CouponCard from "../../../components/CouponCard/CouponCard";
import CouponPage from "../../../components/CouponPage/CouponPage";
import CouponSuccessModal from "../../../components/CouponPage/CouponSuccessModal";

interface Coupon {
  code: string;
  description: string;
  discount_type: string;
  condition: string;
  discount_value: string;
  coupon_id?: string;
  final_amt: number;
  cart_value: number;
  coupon_discount: number;
}

const SellerCart = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const navigate = useNavigate();

  const currentUrl = `${window.location.origin}${location.pathname}${location.search}${location.hash}`;

  const {cartDetails} = useSelector((state: RootState) => state.cart);
  const {user, selectedAddress} = useSelector((state: RootState) => state.user);
  const {details} = useSelector((state: RootState) => state.user.storeFront);

  // const [selectedStoreCart, setSelectedStoreCart] = useState<CartItemDetails | null>(null);
  const [initiateBuyNow, setInitiateBuyNow] = useState(false);
  const [isOpenAddAddress, setIsAddAddressOpen] = useState<boolean>(false);
  const [checkoutAmount, setCheckoutAmount] = useState<number | null>(0);
  const [checkoutQuantity, setCheckoutQuantity] = useState<number>(0);
  const [discountPrice, setDiscountPrice] = useState<number | null>(0);
  const [deliveryCharge, setDeliveryCharge] = useState<number>(0);
  const [tempCartIds, setTempCartIds] = useState<number[]>([]);
  const [initiatePayment, setInitiatePayment] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [screenLoader, setScreenLoader] = useState(false);
  const [couponPage, setCouponPage] = useState(false);
  const [originalTotalAmount, setOriginalTotalAmount] = useState<number | null>(
    0,
  );

  const [quantityLoaders, setQuantityLoaders] = useState<{
    [key: number]: boolean;
  }>({});

  const [, setCartId] = useState<number>(0);
  const [coupon, setCoupon] = useState<Coupon | null>(null);
  const storeId = location?.pathname.split("/")[2];
  const [couponApplied, setCouponApplied] = useState<boolean>(false);
  const fetchCartData = async () => {
    const action = await dispatch(getCartDetailsThunk({storeId}));
    if (getCartDetailsThunk.fulfilled.match(action)) {
      if (action.payload?.cartDetails) {
        const cartData = action.payload?.cartDetails;

        setCartId(cartData[0].cartId);

        const totalItemsInCart = cartData.reduce(
          (sum: number, product: CartItemDetails) =>
            sum + parseFloat(product.quantity?.toString()),
          0,
        );

        if (totalItemsInCart == 0) {
          navigate("/checkout");
        }

        setCheckoutQuantity(totalItemsInCart || 0);

        const totalProductPrice = cartData.reduce(
          (sum: number, product: CartItemDetails) =>
            sum +
            parseFloat(
              (product.product_selling_price * product.quantity).toString(),
            ),
          0,
        );

        setCheckoutAmount(totalProductPrice);

        const originalTotalAmount = cartData.reduce(
          (sum: number, product: CartItemDetails) =>
            sum +
            parseFloat((product.product_price * product.quantity).toString()),
          0,
        );

        setOriginalTotalAmount(originalTotalAmount);
        setDiscountPrice(originalTotalAmount - totalProductPrice);
        setScreenLoader(false);
      }
    }
  };

  useEffect(() => {
    fetchCartData();
  }, []);

  useEffect(() => {
    const fetchDeliveryfee = async () => {
      const charge = await calculateDeliveryCharges(
        details,
        groupedProducts[0],
      );
      setDeliveryCharge(charge);
    };
    fetchDeliveryfee();
  }, [checkoutAmount]);

  const groupedProducts: GroupedStore[] = groupProductsByStore(cartDetails);

  useEffect(() => {
    const fetchStoreDetails = async () => {
      if (groupedProducts[0]?.store_id) {
        const action = await dispatch(
          getStoreFrontDetailsThunk({
            store_name: "",
            storeId: groupedProducts[0].store_id,
            type: "details",
          }),
        );

        if (getStoreFrontDetailsThunk.fulfilled.match(action)) {
          if (action.payload?.data) {
            const storeData = action.payload?.data;
            const charge = await calculateDeliveryCharges(
              storeData,
              groupedProducts[0],
            );
            setDeliveryCharge(charge);
          }
        }
      }
    };

    fetchStoreDetails();
  }, [groupedProducts[0]?.store_id, dispatch]);

  // Handle quantity update
  const handleUpdateQuantity = (
    id: number,
    quantity: number,
    product: CartItemDetails,
  ) => {
    updateCart(quantity, product);
  };

  const updateCart = async (quantity: number, product: CartItemDetails) => {
    // Ensure that selectedStoreCart and quantity are valid
    if (!product || quantity === undefined) {
      console.error("Missing product or quantity for updating the cart.");
      setQuantityLoaders(prev => ({...prev, [product.id]: true}));
      return; // Early return if data is invalid
    }

    // // Construct the payload
    const payload: UpdateCartPayload = {
      cartId: product.cartId,
      product_id: product.product_id.toString(),
      product_pricing_details_id: product.product_pricing_details_id.toString(),
      quantity: quantity.toString(),
    };

    setQuantityLoaders(prev => ({...prev, [product.id]: true}));

    try {
      // Dispatch action to update the cart
      const action = await dispatch(updateCartItemThunk(payload));
      if (updateCartItemThunk.fulfilled.match(action)) {
        fetchCartData();
        // Handle successful cart update here
        setQuantityLoaders(prev => ({...prev, [product.id]: false}));

        toast.success("Cart updated successfully.", {
          toastId: product.cartId,
        });

        setCoupon(null);
      } else {
        // Handle failed cart update here
        setQuantityLoaders(prev => ({...prev, [product.id]: false}));
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const message: any = action?.payload;
        toast.error(message);
      }
    } catch (error) {
      setQuantityLoaders(prev => ({...prev, [product.id]: false}));
      console.error("Error updating cart:", error);
    }
  };

  const handleRemoveproduct = async (product: CartItemDetails) => {
    try {
      const action = await dispatch(
        removeItemFromCartThunk({
          product_id: product.product_id.toString(),
          product_pricing_details_id:
            product?.product_pricing_details_id.toString() || "",
          cart_id: product?.cartId.toString() || "",
        }),
      );
      if (removeItemFromCartThunk.fulfilled.match(action)) {
        fetchCartData();
        toast.success("Product removed succesfully", {
          toastId: product.cartId,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
    // setSelectedStoreCart(null);
  };

  const handleProceedCheckout = () => {
    if (selectedAddress == null) {
      setIsAddAddressOpen(true);
      return;
    }

    setPaymentLoading(true);
    setInitiatePayment(true);
  };

  useEffect(() => {
    if (groupedProducts[0]?.products) {
      const cartIds = groupedProducts[0].products.map(data => data.cartId);
      setTempCartIds(cartIds);
    }
  }, [groupedProducts]);

  const product = {
    selling_price: (checkoutAmount || 0) + deliveryCharge,
    product_id: "",
    varient_id: "",
    cartIds: tempCartIds,
  };

  return (
    <>
      <div>
        {screenLoader && <CustomLoader size={50} />}

        {!couponPage ? (
          groupedProducts &&
          groupedProducts.length > 0 && (
            <>
              <div style={{padding: "20px", paddingTop: "5px"}}>
                <SingleCart
                  cartDetails={groupedProducts[0]}
                  onUpdateQuantity={handleUpdateQuantity}
                  quantityLoaders={quantityLoaders}
                  onDelete={handleRemoveproduct}
                  setQuantityLoaders={setQuantityLoaders}
                />

                <DeliveryAddressCard
                  name={selectedAddress?.address_name || ""}
                  deliverAddress={getAddressStringFromObject(selectedAddress)}
                  isOpenAddAddress={isOpenAddAddress}
                  setIsAddAddressOpen={setInitiateBuyNow}
                />

                {/* coupon */}
                <CouponCard
                  coupon={coupon}
                  couponApplied={false}
                  setCouponPage={setCouponPage}
                />

                <OrderDetailsCard
                  totalItems={checkoutQuantity || 0}
                  priceIncludingGST={originalTotalAmount || 0}
                  discount={discountPrice || 0}
                  deliveryCharge={deliveryCharge}
                  // originalTotalAmount={originalTotalAmount || 0}
                  discountPrice={discountPrice || 0}
                  orderTotal={
                    coupon?.final_amt ?? (checkoutAmount || 0) + deliveryCharge
                  }
                  couponDiscount={coupon?.coupon_discount}
                />

                {/* checkout button */}
                {!initiatePayment ? (
                  <div className="cart_checkout_div">
                    <button
                      onClick={() => handleProceedCheckout()}
                      className="cart_checkout_button">
                      <span className="cart_checkout_text">{`Checkout ₹${convertIntoINR(coupon?.final_amt ?? (checkoutAmount || 0) + deliveryCharge)}`}</span>
                    </button>
                  </div>
                ) : (
                  ""
                )}

                <SelectAddressV2
                  open={initiateBuyNow}
                  onClose={() => {
                    setInitiateBuyNow(false);
                  }}
                  product={product}
                  isOpenAddAddress={isOpenAddAddress}
                  setIsAddAddressOpen={setIsAddAddressOpen}
                />
              </div>
            </>
          )
        ) : (
          <CouponPage
            setCoupon={setCoupon}
            couponApplied={false}
            setCouponPage={setCouponPage}
            setCouponApplied={setCouponApplied}
            cartValue={(checkoutAmount || 0) + deliveryCharge}
            cartId={tempCartIds}
          />
        )}
      </div>

      {/* payment gateway section */}
      {initiatePayment && (
        <Juspay
          userId={user?.user_id || ""}
          initiate={initiatePayment}
          redirectUrl={currentUrl}
          // streamId={streamId}
          product={product}
          addressId={selectedAddress?.id?.toString() || ""}
          storeId={groupedProducts[0]?.store_id || ""}
          deliveryAddress={getAddressStringFromObject(selectedAddress)}
          amount={parseInt(checkoutAmount?.toString() || "0") + deliveryCharge}
          onInitiationComplete={() => {
            setPaymentLoading(false);
          }}
          onInitiationFailure={() => {
            setPaymentLoading(false);
            setInitiatePayment(false);
          }}
          onCancel={() => {
            setPaymentLoading(false);
            setInitiatePayment(false);
            // onClose();
          }}
          type="cart"
        />
      )}

      {paymentLoading && <Loader />}

      <CouponSuccessModal
        isOpen={couponApplied}
        onClose={() => setCouponApplied(false)}
        savedAmount={(coupon?.cart_value || 0) - (coupon?.final_amt || 0)}
        actualAmount={checkoutAmount || 0}
      />
    </>
  );
};

export default SellerCart;

const groupProductsByStore = (products: CartItemDetails[]): GroupedStore[] => {
  // Object to store the grouped products by store id
  const groupedStores: {[key: string]: GroupedStore} = {};

  products.forEach(product => {
    const storeId = product?.seller_store_id;

    // If the store is not already in the groupedStores object, add it
    if (!groupedStores[storeId]) {
      groupedStores[storeId] = {
        store_id: storeId,
        store_name: product.store_name,
        store_profile_url: product.store_profile_url || "", // Use an empty string if no URL is present
        products: [],
      };
    }

    // Push the current product into the appropriate store's product list
    groupedStores[storeId].products.push({
      id: product.id,
      cartId: product.cartId,
      product_id: product.product_id,
      product_pricing_details_id: product.product_pricing_details_id,
      quantity: product.quantity,
      price: product.price,
      product_price: product.product_price,
      product_selling_price: product.product_selling_price,
      product_name: product.product_name,
      product_description: product.product_description,
      image: product.image,
      variants: product.variants,
      seller_store_id: "",
      store_name: "",
    });
  });

  // Convert the groupedStores object to an array
  return Object.values(groupedStores);
};
