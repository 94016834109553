/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import {SelectedEntity, SelectedFilters} from "../../ProductsPage";
import "./FilterBy.style.css";
import CloseButtonSvg from "../../../../../components/SvgImages/CloseButtonSvg/CloseButtonSvg";
interface FilterByProps {
  filterOptions: string[];
  selectedFilterOption: string | undefined;
  selectedFilters: SelectedFilters | undefined;
  filterData: any;
  show: boolean;
  onFilterOptionChange: (value: string) => void;
  onAddFilters: (value: SelectedEntity) => void;
  onRemoveFilter: (value: SelectedEntity) => void;
  onClearFilters: () => void;
  onApplyFilters: () => void;
  onClose: () => void;
}

interface CheckableListItemProps {
  title: string;
  selected: boolean | undefined;
  onPress: () => void;
}

interface CustomButtonProps {
  onPress: () => void;
  title: string;
  buttonContainerStyle?: React.CSSProperties;
  buttonTextStyle?: React.CSSProperties;
  disabled?: boolean;
  loading?: boolean;
  blueIndicatorColor?: boolean;
  activeOpacity?: number;
  className?: string; // Added className here
}

export const FilterBy: React.FC<FilterByProps> = ({
  filterOptions,
  selectedFilterOption,
  selectedFilters,
  filterData,
  show,
  onFilterOptionChange,
  onAddFilters,
  onRemoveFilter,
  onApplyFilters,
  onClearFilters,
  onClose,
}) => {
  if (!show) {
    return null; // If show is false, don't render the modal
  }

  const isSelected = (option: SelectedEntity) => {
    if (selectedFilterOption === "Categories") {
      const result = selectedFilters?.selectedCategories?.some(
        filter => filter.id === option.id,
      );
      return result;
    } else if (selectedFilterOption === "Sub-categories") {
      const result = selectedFilters?.selectedSubCategories?.some(
        filter => filter.id === option.id,
      );
      return result;
    }
    return false;
  };

  const handleFilterOptionChange = (option: string) => {
    onFilterOptionChange(option);
  };

  const CheckableListItem = ({
    title,
    selected,
    onPress,
  }: CheckableListItemProps) => {
    return (
      <div className="checkable-item" onClick={onPress}>
        <span className="item-text">{title}</span>
        <input
          type="checkbox"
          checked={selected}
          onChange={() => {
            onPress();
          }}
          className="check-box"
        />
      </div>
    );
  };

  const CustomButton: React.FC<CustomButtonProps> = ({
    onPress,
    title,
    buttonContainerStyle = {},
    buttonTextStyle = {},
    loading = false,
    disabled = false,
    blueIndicatorColor = false,
    className = "",
  }) => {
    const handleClick = () => {
      onPress();
    };

    return (
      <button
        onClick={handleClick}
        disabled={loading || disabled}
        className={`custom-button ${className} ${disabled || loading ? "button-disabled" : ""}`}
        style={buttonContainerStyle}
        aria-disabled={disabled || loading ? "true" : "false"}>
        {loading ? (
          <div
            className={`button-loading ${blueIndicatorColor ? "blue" : ""}`}></div>
        ) : (
          <span className="button-text" style={buttonTextStyle}>
            {title}
          </span>
        )}
      </button>
    );
  };

  return (
    <div className="filter-modal">
      <div className="filter-modal-content">
        <div className="filter-container-modal">
          <div className="filter-title-container">
            <span className="filter-title-text">Filters</span>
            {/* <button
            onClick={() => {
              onClearFilters();
            }}
            className="clear-all-text">
            Clear All
          </button> */}
            <div
              onClick={() => {
                onClose();
              }}>
              <CloseButtonSvg height={"26px"} width={"26px"} />
            </div>
          </div>
          <div className="filter-options-container">
            <div className="filter-options-list">
              {filterOptions.map((option, index) => (
                <button
                  key={index}
                  onClick={() => handleFilterOptionChange(option)}
                  className={
                    option === selectedFilterOption
                      ? "selected-filter"
                      : "filter-option"
                  }>
                  <span>{option}</span>
                </button>
              ))}
            </div>
            <div className="filter-options-list-options-container">
              {filterData.map((option: any, index: number) => (
                <CheckableListItem
                  key={`${option.id}-${index}`} // Create a unique key using both id and index
                  title={option.name}
                  selected={isSelected(option)}
                  onPress={() => {
                    if (isSelected(option)) {
                      onRemoveFilter(option);
                    } else {
                      onAddFilters(option);
                    }
                  }}
                />
              ))}
            </div>
          </div>
          <div className="filter-footer">
            <div className="filter-footer-buttons">
              <CustomButton
                title={"Clear Filter"}
                onPress={() => {
                  onClearFilters();
                }}
                className={"filter-left-button"}
                buttonTextStyle={
                  {color: "#1E799C"} // Active text style
                }
                // disabled={
                //   selectedFilters?.selectedCategories.length === 0 &&
                //   selectedFilters.selectedSubCategories.length === 0
                // }
              />
              <CustomButton
                title={"Apply Filters"}
                onPress={() => {
                  onApplyFilters();
                }}
                className="filter-right-button"
                buttonTextStyle={{color: "#ffffff"}} // Active text style for Apply button
                disabled={
                  selectedFilters?.selectedCategories.length === 0 &&
                  selectedFilters.selectedSubCategories.length === 0
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="filter-overlay"
        onClick={() => {
          onClose();
        }}></div>
    </div>
  );
};
