import React, {useState} from "react";
import BottomSheet from "../BottomSheet/BottomSheet";
import "./Header.style.css";
import cookieUtils from "../../../utils/cookies";
import {useSelector} from "react-redux";
import {RootState} from "../../../interceptors/store";
import {useNavigate} from "react-router";
import {CategoryMenu} from "../../CategoryMenu/CategoryMenu";
import useMobileSize from "../../../hooks/useMobileSize";
import MobileHeader from "./MobileHeader";
import DesktopHeader from "./DesktopHeader";

const Header: React.FC = () => {
  const [isLogoutBottomSheetOpen, setIsLogoutBottomSheetOpen] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const {isSignedIn} = useSelector((state: RootState) => state.authentication);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleSearch = (query: string) => {
    navigate(`/search?query=${encodeURIComponent(query.trim())}`);
  };

  // const renderMobileView = !["/", "/products", "/checkout"].some(path =>
  //   matchPath({path, end: true}, location.pathname),
  // );

  // const renderCategories = [
  //   "/products",
  //   "/checkout",
  //   "/storefront/:id",
  //   "/stream/:id",
  // ].some(path => matchPath({path, end: false}, location.pathname));

  const isMobile = useMobileSize();

  return (
    <>
      {isMobile ? (
        <MobileHeader
          isSignedIn={isSignedIn}
          handleSearch={handleSearch}
          setIsLogoutBottomSheetOpen={setIsLogoutBottomSheetOpen}
          setIsPopupOpen={setIsPopupOpen}
        />
      ) : (
        <>
          <DesktopHeader
            isSignedIn={isSignedIn}
            handleSearch={handleSearch}
            setIsLogoutBottomSheetOpen={setIsLogoutBottomSheetOpen}
            setIsPopupOpen={setIsPopupOpen}
            isPopupOpen={isPopupOpen}
          />
        </>
      )}

      <BottomSheet
        header="Logout"
        isOpen={isLogoutBottomSheetOpen}
        onClose={() => setIsLogoutBottomSheetOpen(false)}>
        <div className="header-logout-content-container">
          <p style={{color: "black"}}>Are you sure you want to logout?</p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              width: "100%",
              gap: "5%",
            }}>
            <div style={{width: "50%"}}>
              <button
                className="logout-yes-button"
                onClick={() => {
                  localStorage.clear();
                  cookieUtils.removeCookie("token");
                  window.location.href = "/";
                }}>
                Yes
              </button>
            </div>
            <div style={{width: "50%"}}>
              <button
                className="logout-no-button"
                onClick={() => setIsLogoutBottomSheetOpen(false)}>
                No
              </button>
            </div>
          </div>
        </div>
      </BottomSheet>

      <CategoryMenu
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
      />
    </>
  );
};

export default Header;
