import React from "react";
import {Routes, Route} from "react-router-dom";

// Pages import
import DesktopHome from "./pages/Home";
import Login from "./pages/mobile/Login";
import Stream from "./pages/mobile/Stream/Stream";
import StreamVideo from "./pages/mobile/StreamVideo/StreamVideo";
import PaymentConfirmation from "./pages/mobile/PaymentConfirmation/PaymentConfirmation";
import ProductDetails from "./pages/ProductDetails/ProductDetails";
import OrdersPage from "./pages/mobile/OrdersPage/OrdersPage";
import CartPage from "./pages/mobile/Cart/Cart";
import OrderDetailsPage from "./pages/mobile/OrderDetailsPage/OrderDetailsPage";
import MobileHome from "./pages/mobile/Home/MobileHome";
import {Searchpage} from "./pages/mobile/SearchPage/SearchPage";
import Storefront from "./pages/mobile/Storefront/Storefront";
import ProductsPage from "./pages/mobile/ProductsPage/ProductsPage";
import SellerCart from "./pages/mobile/Cart/CheckOutCart";
import {useStorefrontDetection} from "./hooks/useStoreFrontDetection";

const RoutesConfig: React.FC = () => {
  const isStoreFront = useStorefrontDetection();

  return (
    <Routes>
      {/* Route for the home page */}
      <Route
        path="/"
        element={isStoreFront ? <Storefront /> : <DesktopHome />}
      />

      {/* Auth and payment routes */}
      <Route path="/login" element={<Login />} />
      <Route path="/payment" element={<PaymentConfirmation />} />

      {/* Stream-related routes */}
      <Route path="/stream/:id" element={<Stream />} />
      <Route path="/stream/:id/live" element={<StreamVideo />} />

      {/* Product-related routes */}
      <Route path="/product/:id" element={<ProductDetails />} />
      <Route path="/products" element={<ProductsPage />} />

      {/* Order-related routes */}
      <Route path="/orders" element={<OrdersPage />} />
      <Route path="/orders/:orderId" element={<OrderDetailsPage />} />

      {/* Checkout routes */}
      <Route path="/checkout" element={<CartPage />} />
      <Route path="/checkout/:storeId" element={<SellerCart />} />

      {/* Misc routes */}
      <Route path="/search" element={<Searchpage />} />
      <Route path="*" element={<MobileHome />} />

      <Route path="/storefront/:storeId" element={<Storefront />} />
    </Routes>
  );
};

export default RoutesConfig;
