import React from "react";

export interface LessThanSvgProps {
  height: number | string;
  width: number | string;
  color?: string;
}

const LessThanSvg: React.FC<LessThanSvgProps> = ({height, width, color}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none">
      <mask
        id="mask0_102_1393"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24">
        <rect
          x="24"
          y="24"
          width="24"
          height="24"
          transform="rotate(-180 24 24)"
          fill={color ? color : "#D9D9D9"}
        />
      </mask>
      <g mask="url(#mask0_102_1393)">
        <path
          d="M11.0534 12L15.1264 16.0731C15.2649 16.2116 15.3357 16.3856 15.3389 16.5952C15.3421 16.8048 15.2713 16.982 15.1264 17.1269C14.9816 17.2718 14.8059 17.3442 14.5995 17.3442C14.3931 17.3442 14.2175 17.2718 14.0726 17.1269L9.5784 12.6327C9.4848 12.5391 9.41877 12.4404 9.38032 12.3365C9.34186 12.2327 9.32263 12.1205 9.32263 12C9.32263 11.8795 9.34186 11.7673 9.38032 11.6635C9.41877 11.5596 9.4848 11.4609 9.5784 11.3673L14.0726 6.87311C14.2111 6.73465 14.3851 6.66381 14.5947 6.66061C14.8043 6.6574 14.9816 6.72823 15.1264 6.87311C15.2713 7.01798 15.3438 7.19361 15.3438 7.40001C15.3438 7.60641 15.2713 7.78205 15.1264 7.92691L11.0534 12Z"
          fill={color ? color : "#D9D9D9"}
        />
      </g>
    </svg>
  );
};

export default LessThanSvg;
