import React, {useEffect, useState} from "react";
import "./CategoryMenu.css";
import {getCategoriesThunk} from "../../features/User/UserSlice";
import {Category} from "../../features/User/UserInterface";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../interceptors/store";
import {useNavigate} from "react-router";
import useMobileSize from "../../hooks/useMobileSize";
import {MdArrowForwardIos} from "react-icons/md";
interface PopupProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CategoryMenu: React.FC<PopupProps> = ({isOpen, onClose}) => {
  const [isVisible, setIsVisible] = useState(isOpen);
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null,
  );
  const [transitioning, setTransitioning] = useState(false); // Track transition state
  const [categoryId, setCategoryId] = useState<number>();
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const isMobile = useMobileSize();

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
    } else {
      const timer = setTimeout(() => setIsVisible(false), 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const action = await dispatch(getCategoriesThunk());
        if (getCategoriesThunk.fulfilled.match(action)) {
          setCategories(action.payload.categories);
        } else {
          setError("Failed to fetch categories");
        }
      } catch (err) {
        console.error("Error fetching categories:", err);
        setError("An unexpected error occurred while fetching categories");
      }
    };

    fetchCategories();
  }, [dispatch]);

  const handleCategoryClick = (category: Category) => {
    setCategoryId(category.id);
    if (category.subCategory && category.subCategory.length > 0) {
      setTransitioning(true);
      setTimeout(() => {
        setSelectedCategory(category);
        setTransitioning(false);
      }, 300); // Duration of the slide-out animation
    }
  };

  const handleBackClick = () => {
    setTransitioning(true);
    setTimeout(() => {
      setSelectedCategory(null);
      setTransitioning(false);
    }, 300); // Duration of the slide-in animation
  };

  if (!isVisible) return null;

  return (
    <div
      className={`${!isMobile ? "category-popup-overlay-desktop" : "category-popup-overlay"}   ${isOpen ? "open" : "close"}`}>
      <div
        className={`category-popup-content ${isOpen ? "category-slide-up" : "category-slide-down"}`}>
        <div className="category-popup-header">
          {selectedCategory ? (
            <>
              <button
                className="category-back-button"
                onClick={handleBackClick}>
                {"<"} Back
              </button>
              <h3 className="category-popup-title">{selectedCategory.name}</h3>
            </>
          ) : (
            <>
              <h3 className="category-popup-title">Browse Categories</h3>
              <button className="category-popup-close-button" onClick={onClose}>
                X
              </button>
            </>
          )}
        </div>

        {error ? (
          <p className="category-popup-error">{error}</p>
        ) : (
          <div
            className={`category-popup-list-wrapper ${
              transitioning ? (selectedCategory ? "slide-out" : "slide-in") : ""
            }`}>
            <ul className="category-popup-list">
              {selectedCategory
                ? selectedCategory.subCategory.map((sub, index) => (
                    <li
                      key={index}
                      className="category-popup-item"
                      onClick={() => {
                        onClose();
                        navigate(
                          `/products?categories=${categoryId}&subcategories=${sub.id}`,
                        );
                      }}>
                      {sub.name}
                    </li>
                  ))
                : categories.map((category, index) => (
                    <li
                      key={index}
                      className="category-popup-item"
                      onClick={() => handleCategoryClick(category)}>
                      <span> {category.name}</span>{" "}
                      <span>
                        <MdArrowForwardIos />
                      </span>
                    </li>
                  ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};
