import React, {useState} from "react";
import "./Header.style.css";
import {ShoppingCart} from "../../SvgImages/ShoppingCart/ShoppingCart";
import {LoginUserIcon} from "../../SvgImages/LoginUserIcon/LoginUser";
import {StaticSearchBar} from "../../SearchBar/StaticSearchBar";
import {AloliaSearch} from "../../SearchBar/AlgoliaSearch";
import {matchPath, useLocation, useNavigate} from "react-router";
import ZoopLogo from "../../SvgImages/ZooLogo/ZoopGreyLogo";
import Menu from "../Menu/Menu";
import {menuConfig} from "../../../constants/menuConfig";

interface DesktopHeaderProps {
  isSignedIn: boolean;
  handleSearch: (query: string) => void;
  setIsLogoutBottomSheetOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isPopupOpen: boolean;
}

const DesktopHeader: React.FC<DesktopHeaderProps> = ({
  isSignedIn,
  handleSearch,
  setIsLogoutBottomSheetOpen,
  setIsPopupOpen,
  isPopupOpen,
}) => {
  const [searchPage, setSearchPage] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const renderMobileView = !["/", "/products", "/checkout"].some(path =>
    matchPath({path, end: true}, location.pathname),
  );

  const renderCategories = [
    "/products",
    "/checkout",
    "/storefront/:id",
    "/stream/:id",
    "/orders",
  ].some(path => matchPath({path, end: false}, location.pathname));

  return (
    <div className="desktop-home-header">
      <div
        style={{cursor: "pointer"}}
        onClick={() => {
          navigate("/");
          window.location.reload();
        }}>
        <ZoopLogo height={100} width={100} />
      </div>

      {!renderCategories ? (
        <div
          className="categories-menu"
          onClick={e => {
            e.stopPropagation();
            setIsPopupOpen(!isPopupOpen);
          }}>
          <span>Categories</span>
        </div>
      ) : (
        ""
      )}

      {!searchPage ? (
        <StaticSearchBar
          onSearch={handleSearch}
          isVisible={renderMobileView}
          setSearchPage={setSearchPage}
        />
      ) : (
        <AloliaSearch searchPage={searchPage} />
      )}

      <div style={{textAlign: "right"}}>
        {isSignedIn ? (
          <div className="header-left-container">
            <span
              className="header-left-container-cart"
              onClick={() => {
                if (isSignedIn) {
                  navigate("/checkout");
                } else {
                  navigate("/login");
                }
              }}>
              <ShoppingCart width={30} height={30} />
              {location.pathname === "/" ? (
                <span className="header-cart-text">Cart</span>
              ) : (
                ""
              )}
            </span>

            <Menu
              menu={menuConfig}
              onClick={id => {
                if (id === "logout") {
                  setIsLogoutBottomSheetOpen(true);
                }
              }}
            />
          </div>
        ) : (
          <div style={{display: "flex", gap: "1rem"}}>
            <div style={{textAlign: "right"}}>
              <button
                className="header-logout-button"
                onClick={() => {
                  navigate("/login");
                }}>
                <span style={{marginRight: "5px"}}>
                  <LoginUserIcon width={20} height={20} color={"white"} />{" "}
                </span>
                Login
              </button>
            </div>

            {/* <div style={{textAlign: "right"}}>
                <button
                  className="header-logout-button"
                  onClick={() => {
                    window.location.href =
                      "https://docs.google.com/forms/d/e/1FAIpQLSfOy6ZPu-ypYXtkc2s46INnEnd1piGjoIhQg1YF5KULLdhZKQ/viewform";
                  }}>
                  <span style={{marginRight: "5px"}}>
                    <LoginUserIcon width={20} height={20} color={"white"} />{" "}
                  </span>
                  Become a Seller
                </button>
              </div> */}
          </div>
        )}
      </div>
    </div>
    // <div className="desktop-home-header">
    //     <div style={{ display: "flex", justifyContent: "space-between" }}>
    //         <div style={{ cursor: "pointer" }} onClick={() => navigate("/")}>
    //             <ZoopLogo height={50} width={100} />
    //         </div>

    //         <div className="categories-menu" onClick={() => setIsPopupOpen(!false)}>
    //             <span>Categories</span>
    //         </div>

    //         {!searchPage ? (
    //             <StaticSearchBar onSearch={handleSearch} isVisible={true} setSearchPage={setSearchPage} />
    //         ) : (
    //             <AloliaSearch searchPage={searchPage} />
    //         )}

    //         <div style={{ textAlign: "right" }}>
    //             {isSignedIn ? (
    //                 <>
    //                     <div className="header-left-container-cart" onClick={() => navigate("/checkout")}>
    //                         <ShoppingCart width={30} height={30} />
    //                         <span className="header-cart-text">Cart</span>
    //                     </div>
    //                     <Menu menu={[]} onClick={() => setIsLogoutBottomSheetOpen(true)} />
    //                 </>
    //             ) : (
    //                 <button className="header-logout-button" onClick={() => navigate("/login")}>
    //                     <span style={{ marginRight: "5px" }}>
    //                         <LoginUserIcon width={20} height={20} color={"white"} />
    //                     </span>
    //                     Login
    //                 </button>
    //             )}
    //         </div>
    //     </div>
    // </div>
  );
};

export default DesktopHeader;
