import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import "./CouponPage.css";
import {GoArrowLeft} from "react-icons/go";
import {
  getCouponsList,
  getCouponCalculatedAmount,
} from "../../features/Coupon/CouponApi";
import {IoInformationCircle} from "react-icons/io5";

import Loader from "../../Loader/Loader";
import {toast} from "react-toastify";
interface CouponPageProps {
  setCoupon: Dispatch<SetStateAction<Coupon | null>>;
  couponApplied: boolean;
  setCouponPage: Dispatch<SetStateAction<boolean | false>>;
  setCouponApplied: Dispatch<SetStateAction<boolean | false>>;
  cartValue: number;
  cartId: number[];
}

interface Coupon {
  code: string;
  description: string;
  discount_type: string;
  condition: string;
  discount_value: string;
  max_discount_value: string;
  coupon_id?: string;
  final_amt: number;
  cart_value: number;
  coupon_discount: number;
}

const CouponPage: React.FC<CouponPageProps> = ({
  setCoupon,
  setCouponPage,
  cartValue,
  setCouponApplied,
  cartId,
}) => {
  const [couponInput, setCouponInput] = useState("");
  const [coupons, setCoupons] = useState<Coupon[]>([]); // Array of Coupon objects

  const [applyLoader, setApplyLoader] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleApplyCoupon = async (coupon: Coupon) => {
    setApplyLoader(true); // Show loader when coupon is being applied

    try {
      // Prepare API request payload
      const payload = {
        cart_ids: cartId,
        coupon_id: coupon.code,
        user_id: localStorage.getItem("userId"),
        cart_delivery_address_id: null,
      };

      const response = await getCouponCalculatedAmount(payload);

      // Check if response has the expected structure
      if (response && response.final_amt !== undefined) {
        const discount = cartValue - response.final_amt;

        // Update coupon object with new values
        const updatedCoupon = {
          ...coupon,
          final_amt: response.final_amt,
          cart_value: cartValue,
          coupon_discount: discount,
        };

        // Update state with the new coupon information
        setCoupon(updatedCoupon);
        setCouponPage(false);
        setCouponApplied(true);
      } else {
        setErrorMessage("Coupon is not valid");
        toast.error("Coupon is not valid");
        // Handle case where the response doesn't have expected structure
        console.log("Invalid response structure:", response);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // Handling different error types
      if (error.response) {
        toast.error("Coupon is not valid");
        setErrorMessage("Coupon is not valid");
      } else if (error.request) {
        setErrorMessage("Coupon is not valid");
        toast.error("Coupon is not valid");
      } else {
        setErrorMessage(error.message);
        toast.error(error.message, {
          toastId: Math.random() * 100,
        });
      }
    } finally {
      // Hide loader after the process completes
      setApplyLoader(false);
    }
  };

  const handleApplyValue = async (code: string) => {
    setApplyLoader(true); // Show loader when coupon is being applied

    try {
      // Prepare API request payload
      const payload = {
        cart_ids: cartId,
        coupon_id: code.toUpperCase(),
        user_id: localStorage.getItem("userId"),
        cart_delivery_address_id: null,
      };

      const response = await getCouponCalculatedAmount(payload);

      // Check if response has the expected structure
      if (response && response.final_amt !== undefined) {
        const discount = cartValue - response.final_amt;

        // Update coupon object with new values
        const updatedCoupon = {
          code: code.toUpperCase(),
          final_amt: response.final_amt,
          cart_value: cartValue,
          coupon_discount: discount,
          description: "",
          discount_type: "",
          discount_value: "",
          max_discount_value: "",
          condition: "",
        };

        // Update state with the new coupon information
        setCoupon(updatedCoupon);
        setCouponPage(false);
        setCouponApplied(true);
      } else {
        setErrorMessage("Coupon is not valid");
        toast.error("Coupon is not valid");
        // Handle case where the response doesn't have expected structure
        console.log("Invalid response structure:", response);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // Handling different error types
      if (error.response) {
        toast.error("Coupon is not valid");
        setErrorMessage("Coupon is not valid");
      } else if (error.request) {
        setErrorMessage("Coupon is not valid");
        toast.error("Coupon is not valid");
      } else {
        setErrorMessage(error.message);
        toast.error(error.message, {
          toastId: Math.random() * 100,
        });
      }
    } finally {
      // Hide loader after the process completes
      setApplyLoader(false);
    }
  };

  const handleBack = () => {
    setCouponPage(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getCouponsList();
        setCoupons(response.data?.coupons);
      } catch (error) {
        console.error("Error fetching coupons:", error);
      }
    };

    fetchData();
  }, []);

  const calculateDiscount = (coupon: Coupon) => {
    if (coupon.discount_type === "FIXED") {
      return "₹" + parseInt(coupon.discount_value);
    } else {
      const calculatedDiscount =
        (parseInt(coupon.discount_value) * cartValue) / 100;
      const maxDiscount = Number(coupon?.max_discount_value || 0);
      const finalDiscount =
        maxDiscount > 0
          ? Math.min(calculatedDiscount, maxDiscount)
          : calculatedDiscount;

      return "₹" + parseInt(finalDiscount.toString());
    }
  };

  return (
    <div className="apply-coupon-page">
      <div
        style={{
          padding: "16px",
          paddingBottom: "0px",
        }}>
        <div className="apply-coupon-header">
          <div className="header-left" onClick={handleBack}>
            <GoArrowLeft className="back-arrow" />
          </div>
          <div>
            <h5>Apply Coupon</h5>
            <p className="cart-value">Cart value: ₹{cartValue}</p>
          </div>
        </div>

        {applyLoader ? <Loader /> : ""}
        <div className="apply-coupon-input">
          <input
            type="text"
            placeholder="Enter Coupon Code"
            value={couponInput}
            onChange={e => setCouponInput(e.target.value)}
          />
          <button
            style={{
              color: couponInput.length > 0 ? "#1E799C" : "#938e9e",
            }}
            disabled={!couponInput}
            onClick={() => handleApplyValue(couponInput)}>
            Apply
          </button>
        </div>
      </div>
      <div>
        {errorMessage.length > 0 ? (
          <p className="error-text">
            {" "}
            <IoInformationCircle
              size={20}
              style={{
                margin: 0,
              }}
            />{" "}
            <span>{errorMessage}</span>
          </p>
        ) : (
          ""
        )}
      </div>
      <div className="apply-coupon-list">
        {coupons.length > 0 ? (
          coupons.map((coupon, index) => (
            <div key={index} className="coupon-card">
              <div className="coupon-info">
                <h3>{coupon.code}</h3>
                <h5
                  style={{
                    color: "#068A0C",
                    padding: 0,
                    margin: 0,
                  }}>
                  SAVE {calculateDiscount(coupon)} on this order
                </h5>
                <span>
                  GET FLAT{" "}
                  {coupon.discount_type === "FIXED"
                    ? "₹" + parseInt(coupon.discount_value)
                    : `${parseInt(coupon.discount_value)}%${
                        coupon?.max_discount_value &&
                        coupon.max_discount_value !== null
                          ? ` upto ₹${parseInt(coupon.max_discount_value)}`
                          : ""
                      }`}
                </span>
                <p>{coupon.description}</p>
              </div>
              <button onClick={() => handleApplyCoupon(coupon)}>Apply</button>
            </div>
          ))
        ) : (
          <p>No available coupons at the moment.</p>
        )}
      </div>
    </div>
  );
};

export default CouponPage;
