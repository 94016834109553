/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useRef, useMemo, useState} from "react";
import {MdArrowForwardIos} from "react-icons/md";
import "./Chat.style.css";
import {toast} from "react-toastify";
import Avatar from "../Avatar/Avatar";
import {useSelector} from "react-redux";
import {
  convertTimestampIntoTime,
  getSecondsPassed,
} from "../../../utils/helpers";
import ChatSuggestionModal from "../ChatSuggestionModal/ChatSuggestionModal";
import useDebounce from "../../../hooks/useDebounce";
import {Socket} from "socket.io-client";
import {RootState} from "../../../interceptors/store";
import {StreamVariantMapping} from "../../../features/LiveStream/LiveStreamInterface";

interface Message {
  user: string;
  text: string;
  type: number;
  created_at?: Date;
  fullname?: string;
}

interface UserColorMapping {
  [user: string]: {
    uname: string;
    color: string;
  };
}

interface ChatProps {
  username: string;
  roomId: string;
  socket: Socket; // Consider using a stricter type for the socket
  onProductActivated: (products: any) => void;
  onChangeUserCount: (count: number) => void;
  onUpdateVariantCount: (products: StreamVariantMapping[]) => void;
  show: boolean;
  onConnect: () => void;
}

const colorClasses = [
  "bg-slate",
  "bg-teal",
  "bg-red",
  "bg-green",
  "bg-yellow",
  "bg-gray",
  "bg-cyan",
  "bg-sky",
  "bg-blue",
];

const Chat: React.FC<ChatProps> = ({
  username,
  roomId,
  socket,
  onProductActivated,
  onChangeUserCount,
  onUpdateVariantCount,
  show,
  onConnect,
}) => {
  const [currentMessage, setCurrentMessage] = useState<string>("");
  const [messageList, setMessageList] = useState<Message[]>([]);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [filteredUsers, setFilteredUsers] = useState<any[]>([]);
  const {user} = useSelector((state: RootState) => state.user);

  const userColorMapping: UserColorMapping = useMemo(() => {
    const mapping: UserColorMapping = {};
    messageList.forEach((message, index) => {
      if (!mapping[message.user]) {
        mapping[message.user] = {
          uname: message.user,
          color: colorClasses[index % colorClasses.length],
        };
      }
    });
    return mapping;
  }, [messageList]);

  useEffect(() => {
    scrollToBottom();
  }, [messageList]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({behavior: "smooth"});
  };

  const sendMessage = async () => {
    if (currentMessage.trim() !== "") {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      socket.emit("sendMessage", currentMessage, (message: Message) => {
        setMessageList(list => (list ? [...list] : []));
        setCurrentMessage("");
      });
    }
  };

  // const debouncedHandleChange = useDebounce(async () => {
  //   joinRoom();
  // }, 1000);

  useEffect(() => {
    if (socket && username && roomId) {
      joinRoom();
    }
  }, [socket, username, roomId]);

  const joinRoom = async () => {
    socket.emit(
      "signin",
      {
        user: username,
        room: roomId,
        fullname: user?.first_name
          ? `${user?.first_name?.trim()}${
              user?.middle_name ? ` ${user.middle_name?.trim()}` : ""
            }${user?.last_name ? ` ${user.last_name?.trim()}` : ""}`
          : "User",
      },
      (err: Error, response: any) => {
        if (err) {
          console.error(err);
        } else if (response && response.messages) {
          setMessageList(response.messages);
        } else {
          console.log("Unexpected response format", response);
        }
      },
    );
  };

  useEffect(() => {
    socket.on("connect", () => {
      onConnect();
    });

    socket.on("disconnect", (err: any) => {
      if (err === "io server disconnect") {
        socket.connect();
      }
    });

    socket.io.on("reconnect", () => {
      socket.emit(
        "updateSocketId",
        {user: username, room: roomId},
        (error: Error) => {
          if (!error) onConnect();
        },
      );
    });

    socket.on("ping", () => {
      socket.emit("pong");
    });

    socket.on("message", (data: Message) => {
      setMessageList(list => [
        ...list,
        {...data, time: new Date().toLocaleTimeString()},
      ]);
    });

    socket.on(
      "productActivated",
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ({success, productInfo}: {success: boolean; productInfo: any}) => {
        if (productInfo) {
          const updatedProducts = Array.isArray(productInfo)
            ? productInfo
            : [productInfo];
          onProductActivated(updatedProducts);
        }
      },
    );

    socket.on("notification", (data: any) => {
      if (data.for_buyer) toast.success(data.description);
    });

    socket.on("roomUserCount", onChangeUserCount);

    socket.on("updateVarientCount", (productInfo: any) => {
      const updatedProducts = Array.isArray(productInfo)
        ? productInfo
        : [productInfo];
      onUpdateVariantCount(updatedProducts);
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("notification");
      socket.off("message");
      socket.off("productActivated");
      socket.off("ping");
      socket.off("updateVarientCount");
      socket.off("roomUserCount");
    };
  }, [socket]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setCurrentMessage(value);

    const atSymbolIndex = value.indexOf("@");
    const textAfterAt: string = value.slice(atSymbolIndex + 1).trim();

    if (
      atSymbolIndex !== -1 &&
      textAfterAt.length > 0 &&
      !textAfterAt.startsWith(" ")
    ) {
      setShowModal(true);
      const query: string = textAfterAt?.split("@")?.pop()?.toLowerCase() ?? "";

      const matchedUsers = Object.values(userColorMapping)
        .filter(user => user.uname.toLowerCase().includes(query))
        .slice(0, 3);

      setFilteredUsers(matchedUsers.length ? matchedUsers : []);
      if (!matchedUsers.length) setShowModal(false);
    } else {
      setShowModal(false);
      setFilteredUsers([]);
    }
  };

  const handleUserSelect = (user: string) => {
    const parts = currentMessage.split("@");
    parts.pop();
    const newMessage = `${parts.join("@")}@${user} `;
    setCurrentMessage(newMessage);
    setShowModal(false);
  };

  return (
    <div className="chat-box" style={{display: show ? "initial" : "none"}}>
      <div className="chat-message">
        {messageList.map((messageContent, index) => (
          <div
            className="user-info"
            key={index}
            style={{
              display:
                messageContent.type === 1 &&
                getSecondsPassed(messageContent.created_at) >= 10
                  ? "none"
                  : "initial",
            }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginBottom: "2vh",
              }}>
              <Avatar
                name={messageContent.user}
                width={35}
                height={35}
                colorClass={userColorMapping[messageContent.user]?.color || ""}
                fullname={messageContent.fullname}
              />
              <div className="chat-message-history">
                <div className="chat-message-header">
                  <span className="username">
                    {messageContent.user}
                    {username === messageContent.user ? "*" : ""}
                  </span>
                  <span className="chat-timestamp">
                    {messageContent.created_at
                      ? convertTimestampIntoTime(messageContent.created_at)
                      : ""}
                  </span>
                </div>
                <div className="message">
                  {messageContent?.text.split(" ").map((word, i) =>
                    word.startsWith("@") ? (
                      <span key={i} className="chat-highlight-mention">
                        {word}{" "}
                      </span>
                    ) : (
                      <span key={i}>{word} </span>
                    ),
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="mention-suggestion">
        {showModal && (
          <ChatSuggestionModal
            users={filteredUsers}
            onSelect={handleUserSelect}
          />
        )}
      </div>
      <div className="chat-input">
        <input
          type="text"
          value={currentMessage}
          placeholder="Say Hi!"
          onChange={handleInputChange}
          onKeyDown={e => e.key === "Enter" && sendMessage()}
        />
        <button className="send-button" onClick={sendMessage}>
          <MdArrowForwardIos size="18px" />
        </button>
      </div>
    </div>
  );
};

export default Chat;
