import React from "react";
import "./Category.css";
/* eslint-disable @typescript-eslint/no-explicit-any */
import {useNavigate} from "react-router";

const CategorySection = () => {
  // const { categories, loading } = useSelector((state: RootState) => state.user);

  const Categories = [
    [
      {
        image:
          "https://storage.googleapis.com/zoop-public-bucket/WEB-Category/vinyl/cd1.png",
        product_id: "1727852998",
      },
      {
        image:
          "https://storage.googleapis.com/zoop-public-bucket/WEB-Category/vinyl/vinyl2.webp",
        product_id: "1727851797",
      },
      {
        image:
          "https://storage.googleapis.com/zoop-public-bucket/WEB-Category/vinyl/vinyl3.webp",
        product_id: "1731564640",
      },
      {
        image:
          "https://storage.googleapis.com/zoop-public-bucket/WEB-Category/vinyl/vinyl4.jpeg",
        product_id: "1732004362",
      },
    ],
    [
      {
        image:
          "https://storage.googleapis.com/zoop-public-bucket/WEB-Category/jwellery/jwel1.jpeg",
        product_id: "1731864397",
      },
      {
        image:
          "https://storage.googleapis.com/zoop-public-bucket/WEB-Category/jwellery/jwel2.jpeg",
        product_id: "1731924182",
      },
    ],
    [
      {
        image:
          "https://storage.googleapis.com/zoop-public-bucket/WEB-Category/women_wears_top/top1.png",
        product_id: "1732190716",
      },
      {
        image:
          "https://storage.googleapis.com/zoop-public-bucket/WEB-Category/women_wears_top/top2.png",
        product_id: "1732255019",
      },
      {
        image:
          "https://storage.googleapis.com/zoop-public-bucket/WEB-Category/women_wears_top/top3.jpeg",
        product_id: "",
      },
      {
        image:
          "https://storage.googleapis.com/zoop-public-bucket/WEB-Category/women_wears_top/top4.jpeg",
        product_id: "1730640496",
      },
    ],
    [
      {
        image:
          "https://storage.googleapis.com/zoop-public-bucket/WEB-Category/jackets/jackets1.webp",
        product_id: "1731346841",
      },
      {
        image:
          "https://storage.googleapis.com/zoop-public-bucket/WEB-Category/jackets/jacket2.jpeg",
        product_id: "1726557185",
      },
      {
        image:
          "https://storage.googleapis.com/zoop-public-bucket/WEB-Category/jackets/jacket3.jpeg",
        product_id: "1732296732",
      },
      {
        image:
          "https://storage.googleapis.com/zoop-public-bucket/WEB-Category/jackets/jacket4.jpeg",
        product_id: "1732187850",
      },
      {
        image:
          "https://storage.googleapis.com/zoop-public-bucket/WEB-Category/jackets/jacket5.jpeg",
        product_id: "1731385286",
      },
    ],
    [
      {
        image:
          "https://storage.googleapis.com/zoop-public-bucket/WEB-Category/arts/art1.jpeg",
        product_id: "1732013084",
      },
      {
        image:
          "https://storage.googleapis.com/zoop-public-bucket/WEB-Category/arts/art2.jpeg",
        product_id: "1732012557",
      },
    ],
  ];

  return (
    <section className="category-section">
      <div className="category-product-grid">
        <CategoryCard
          images={Categories[0]}
          className=""
          featured={false}
          categoryName="Vinyl Records"
          categoryId={8}
          subCategoryId={71}
        />
        <CategoryCard
          images={Categories[2]}
          featured={false}
          className="hide-on-mobile"
          categoryName={`Women's Tops`}
          categoryId={2}
          subCategoryId={50}
        />
        <CategoryCardV2
          images={Categories[3]}
          featured={true}
          className="hide-on-mobile"
          categoryName="Racing Jackets"
          categoryId={1}
          subCategoryId={3}
        />
        <CategoryCard
          images={Categories[1]}
          className="hide-on-mobile"
          featured={false}
          categoryName="Handmade Gems"
          categoryId={7}
          subCategoryId={35}
        />
        <CategoryCard
          images={Categories[4]}
          featured={false}
          className=""
          categoryName="Arts, Crafts and Stationery"
          categoryId={8}
          subCategoryId={103}
        />
      </div>
    </section>
  );
};

export default CategorySection;

interface CategoryProps {
  images: any;
  featured: boolean;
  categoryName: string;
  className: string;
  categoryId: number;
  subCategoryId: number;
}

interface imgSrc {
  image: string;
  product_id: string;
}

const CategoryCard: React.FC<CategoryProps> = ({
  images,
  featured,
  className,
  categoryName,
  categoryId,
  subCategoryId,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className={`category-product-card ${featured ? "featured-card" : ""} ${className}`}
      onClick={() => {
        navigate(
          `/products?categories=${categoryId}&subcategories=${subCategoryId}`,
        );
      }}>
      <div className="heading">
        {/* <span>Zoop</span> */}
        <span>{categoryName}</span>
      </div>
      <div className="images-grid">
        {images.map((imgSrc: imgSrc, index: any) => {
          return (
            <img
              loading={className ? "lazy" : "eager"}
              srcSet={imgSrc.image + " 172w"}
              key={index}
              src={imgSrc.image}
              alt={`https://storage.googleapis.com/zoop-public-bucket/ZOOP-RECOMMENDS/productstock.png`}
              onClick={() => {
                if (imgSrc?.product_id) {
                  navigate(`/product/${imgSrc?.product_id}`);
                }
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

const CategoryCardV2: React.FC<CategoryProps> = ({
  images,
  featured,
  className,
  categoryName,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className={`category-product-card ${featured ? "featured-card" : ""} ${className}`}
      onClick={() => {
        navigate(`/storefront/5253f827-e688-436b-8f82-b777790369ab`);
      }}>
      <div className="heading">
        <span>{categoryName}</span>
      </div>
      <div className="images-grid-v2">
        {images.map((imgSrc: imgSrc, index: any) => (
          <img
            loading={className ? "lazy" : "eager"}
            srcSet={imgSrc.image + " 172w"}
            key={index}
            src={imgSrc.image}
            alt={`Product`}
          />
        ))}
      </div>
    </div>
  );
};
