import React, {useEffect, useState} from "react";
import "react-toastify/dist/ReactToastify.css";
import "./ShopProducts.style.css";
import {useNavigate} from "react-router";
import {convertIntoINR} from "../../../utils/helpers";
import BottomSheet from "../BottomSheet/BottomSheet";
import SelectAddress from "../SelectAddress/SelectAddress";
import DynamicTabs from "../DynamicTabs/DynamicTabs";
import NoProductSvg from "../../SvgImages/NoProductSvg/NoProductSvg";
import {StreamVariantMapping} from "../../../features/LiveStream/LiveStreamInterface";
import {Socket} from "socket.io-client";
interface NoProductAvailableProps {
  text?: string;
  subText?: string;
}

const NoProductAvailable: React.FC<NoProductAvailableProps> = ({
  text,
  subText,
}) => {
  return (
    <div className="shop-no-product-container">
      <NoProductSvg width={60} height={60} />
      <h3 className="shop-no-product-title">
        {text || "No Product Available"}
      </h3>
      <p className="shop-no-product-subtext">
        {subText || "Please check back later or explore other categories."}
      </p>
    </div>
  );
};

interface ShopProductProps {
  product: StreamVariantMapping;
  type: "buyNow" | "upcoming";
  onClickBuyNow?: (product: StreamVariantMapping) => void;
  streamId?: string;
  socket: Socket;
}

interface Product {
  product_id: number;
  varient_id: string;
  quantity: number;
  hold_count: number;
}

const ShopProduct: React.FC<ShopProductProps> = ({
  product,
  type,
  onClickBuyNow,
  streamId,
  socket,
}) => {
  const navigate = useNavigate();
  const discount =
    parseInt(product.cost_price?.toString()) -
    parseInt(product.selling_price?.toString());

  const [updatedProducts, setUpdatedProducts] = useState<Product[]>([]);

  useEffect(() => {
    socket.on("updateVarientCount", (productInfo: Product) => {
      const updatedProducts = Array.isArray(productInfo)
        ? productInfo
        : [productInfo];
      // onUpdateVariantCount(updatedProducts);
      setUpdatedProducts(updatedProducts);
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("notification");
      socket.off("message");
      socket.off("productActivated");
      socket.off("ping");
      socket.off("pong");
      socket.off("updateVarientCount");
    };


  }, [socket]);

  // Function to find a product by ID
  function findProductById(
    products: Product[],
    productId: number,
  ): Product | undefined {
    return products.find(product => product.product_id === productId);
  }

  const wsProduct = findProductById(updatedProducts, product?.product_id);

  const product_availaibility_chip = {
    available: wsProduct?.quantity || product?.varient_count || 0,
    on_hold: wsProduct?.hold_count || product?.hold_count || 0,
    bought:
      product?.initial_variant_count -
        product?.varient_count -
        product?.hold_count || 0,
  };

  return (
    <div className="shop-product-container">
      <div className="shop-products-body">
        <div className="shop-product-item">
          <div className="shop-product-image-wrapper">
            {product.varient_count === 0 && (
              <div className="sold-out-strip">
                <span>Sold Out</span>
              </div>
            )}
            <img
              src={product.image_url[0] || ""}
              className={`shop-product-image${product.varient_count === 0 ? " sold-out-image" : ""}`}
              alt={product.varient_name}
            />
          </div>
        </div>
        <div className="shop-product-item">
          <div className="shop-product-item-header">
            <h5 className="shop-product-title">{product.varient_name}</h5>
            {/* <h5 className="shop-product-subtitle">
              {product.varient_count}{" "}
              {product.varient_count === 1 ? "piece" : "pieces"} left
            </h5> */}
          </div>

          <div className="shop-product-chips">
            <span className="chip available">
              Available:
              <span
                style={{
                  fontWeight: 600,
                }}>
                {product_availaibility_chip?.available}
              </span>
            </span>
            <span className="chip on-hold">
              On-Hold:
              <span
                style={{
                  fontWeight: 600,
                }}>
                {product_availaibility_chip?.on_hold}
              </span>
            </span>
            <span className="chip bought">
              Bought:
              <span
                style={{
                  fontWeight: 600,
                }}>
                {product_availaibility_chip?.bought}
              </span>
            </span>
          </div>

          <div className="shop-product-item-body">
            <span className="shop-product-selling-price">
              {discount > 0 && (
                <span className="shop-product-cost-price">
                  ₹{convertIntoINR(product.cost_price)}
                </span>
              )}
              ₹{convertIntoINR(product.selling_price)}
            </span>
            {discount > 0 && (
              <div className="shop-product-discount-container">
                <div className="shop-product-line"></div>
                <span className="shop-product-discount">
                  {(
                    (discount / parseInt(product.cost_price?.toString())) *
                    100
                  ).toFixed(0)}
                  % off
                </span>
              </div>
            )}
          </div>

          {type === "buyNow" && (
            <div className="shop-product-item-footer">
              {product.varient_count !== 0 && onClickBuyNow && (
                <button
                  className="shop-product-button-container"
                  onClick={() => onClickBuyNow(product)}>
                  <span className="shop-product-button-text">Buy Now</span>
                </button>
              )}
              <button
                className="shop-product-secondary-button-container"
                onClick={() =>
                  navigate(`/product/${product.product_id}`, {
                    state: {
                      product_variant_id: product.varient_id,
                      stream_id: streamId,
                    },
                  })
                }>
                <span className="shop-product-secondary-button-text">
                  View Product
                </span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

interface UpcomingProps {
  upcomingProducts: StreamVariantMapping[];
  storeId: string;
  socket: Socket;
}

const Upcoming: React.FC<UpcomingProps> = ({upcomingProducts, socket}) => {
  return (
    <div
      className="shop-products-upcoming-products-container"
      style={{
        background: upcomingProducts.length === 0 ? "white" : " #fef7ec",
      }}>
      <div className="shop-products-upcoming-products-content-container">
        {upcomingProducts.length === 0 ? (
          <NoProductAvailable
            text="No Upcoming Products"
            subText="All our amazing products are currently active!"
          />
        ) : (
          upcomingProducts.map(product => (
            <ShopProduct
              key={product.id}
              product={product}
              type="upcoming"
              socket={socket}
            />
          ))
        )}
      </div>
    </div>
  );
};

interface BuyNowProps {
  buyNowProducts: StreamVariantMapping[];
  storeId: string;
  streamId: string;
  setSelectedBuyNowProduct: (product: StreamVariantMapping) => void;
  onClose?: () => void;
  setIsSelectAddressOpen: (open: boolean) => void;
  socket: Socket;
}

const BuyNow: React.FC<BuyNowProps> = ({
  buyNowProducts,
  streamId,
  setSelectedBuyNowProduct,
  setIsSelectAddressOpen,
  socket,
}) => {
  return (
    <div className="shop-products-buy-now-products-container">
      <hr style={{border: "1px solid #E5E7EB"}} />
      <div className="shop-products-buy-now-products-content-container">
        {buyNowProducts.length === 0 ? (
          <NoProductAvailable
            text="No active products yet"
            subText="All our amazing products will be active soon!"
          />
        ) : (
          buyNowProducts.map((product, index) => (
            <ShopProduct
              key={index}
              product={product}
              type="buyNow"
              streamId={streamId}
              onClickBuyNow={selectedProduct => {
                setSelectedBuyNowProduct(selectedProduct);
                setIsSelectAddressOpen(true);
              }}
              socket={socket}
            />
          ))
        )}
      </div>
    </div>
  );
};

interface ShopProductsProps {
  open: boolean;
  onClose: () => void;
  products: StreamVariantMapping[];
  storeId: string;
  streamId: string;
  socket: Socket;
}

const ShopProducts: React.FC<ShopProductsProps> = ({
  open,
  onClose,
  products,
  storeId,
  streamId,
  socket,
}) => {
  const upcomingProducts = products.filter(
    product => product.is_marked_active === 0,
  );
  const buyNowProducts = products.filter(
    product => product.is_marked_active === 1,
  );

  const [isSelectAddressOpen, setIsSelectAddressOpen] = useState(false);
  const [selectedBuyNowProduct, setSelectedBuyNowProduct] =
    useState<StreamVariantMapping>();

  useEffect(() => {
    if (isSelectAddressOpen) {
      onClose();
    }
  }, [isSelectAddressOpen, onClose]);

  const tabData = [
    {
      label: "Buy Now",
      content: (
        <BuyNow
          buyNowProducts={buyNowProducts}
          storeId={storeId}
          streamId={streamId}
          setSelectedBuyNowProduct={setSelectedBuyNowProduct}
          onClose={onClose}
          setIsSelectAddressOpen={setIsSelectAddressOpen}
          socket={socket}
        />
      ),
    },
    {
      label: "Upcoming",
      content: (
        <Upcoming
          upcomingProducts={upcomingProducts}
          storeId={storeId}
          socket={socket}
        />
      ),
    },
  ];

  return (
    <React.Fragment>
      <BottomSheet header="Shop" isOpen={open} onClose={onClose}>
        <div className="shop-products-container">
          <DynamicTabs tabs={tabData} />
        </div>
      </BottomSheet>
      {selectedBuyNowProduct && (
        <SelectAddress
          streamId={streamId}
          storeId={storeId}
          open={isSelectAddressOpen}
          onClose={() => setIsSelectAddressOpen(false)}
          product={selectedBuyNowProduct}
        />
      )}
    </React.Fragment>
  );
};

export default ShopProducts;
