/* eslint-disable @typescript-eslint/no-explicit-any */
import {api, apiConfig} from "../../interceptors/api";
import {
  GetCouponListResponse,
  GetCouponCaculatedAmountResponse,
  ErrorResponse,
} from "./CouponInterface";

// Function to get the list of coupons
export const getCouponsList = async (): Promise<GetCouponListResponse> => {
  try {
    const response = await api.get(`${apiConfig.inventoryUrl}/api/coupon`);
    return response.data;
  } catch (error) {
    // Log or handle the error more gracefully
    console.error("Error fetching coupon list:", error);
    throw error; // Re-throwing the original error to preserve stack trace
  }
};

// Function to calculate the coupon's discounted amount
export const getCouponCalculatedAmount = async (
  payload: any,
): Promise<GetCouponCaculatedAmountResponse> => {
  try {
    const response = await api.post(
      `${apiConfig.inventoryUrl}/api/coupon/calculate_amount`,
      payload,
    );
    return response.data;
  } catch (error: any) {
    // Handle errors more specifically
    if (error.response) {
      // If the error is an Axios error with a response from the server
      const errorResponse: ErrorResponse = error.response.data;
      throw new Error(errorResponse.error || "Unknown error occurred");
    } else {
      throw new Error("Network error or no response from the server");
    }
  }
};
