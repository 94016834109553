import React from "react";
import "./ProductCard.style.css";
import {Variant} from "../../../../../features/Order/OrderInterface";
import {useNavigate} from "react-router";

interface ProductCardProps {
  productName: string;
  productDescription: string;
  productQuantity: number;
  productImage: string;
  variants: Variant[];
  product_id: string;
}

const ProductCard: React.FC<ProductCardProps> = ({
  productName,
  productDescription,
  productQuantity,
  productImage,
  variants,
  product_id,
}) => {
  const navigate = useNavigate();

  return (
    <div className="order-details-product-card-container">
      <div className="order-details-product-container">
        {/* Left Section */}
        <div
          className="order-details-product-left-container"
          onClick={() => {
            navigate(`/product/${product_id}`);
          }}>
          <img
            src={
              productImage ||
              "https://storage.googleapis.com/zoop-public-bucket/ZOOP-RECOMMENDS/productstock.png"
            }
            alt={productName}
            className="order-details-product-image"
          />
        </div>

        {/* Right Section */}
        <div className="order-details-product-right-container">
          <p className="order-details-product-name" title={productName}>
            {productName}
          </p>
          <p
            className="order-details-product-description"
            title={productDescription}>
            {productDescription}
          </p>

          <div className="order-details-button-container">
            <div className="order-details-quantity-container">
              <div className="order-details-dropdown-button-container">
                <span className="order-details-dropdown-button-text">
                  Quantity - {productQuantity}
                </span>
              </div>
            </div>

            {variants &&
              variants.length > 0 &&
              variants?.[0] &&
              variants?.[0].label !== "No Variant" && (
                <div className="order-details-dropdown-button-container">
                  {variants.map((variant, index) => {
                    return (
                      <div
                        key={index}
                        className={`order-details-variant-container ${index > 1 ? "hidden" : ""}`}>
                        <span className="dropdown-button-text-order-details">
                          {variant?.label?.toLowerCase().includes("size")
                            ? "Size"
                            : variant.label}{" "}
                          - {variant.value}
                        </span>
                      </div>
                    );
                  })}
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
