import React from "react";
import {DiscountPercentageSvg} from "../SvgImages/DiscountPercentageSvg/DiscountPercentageSvg";

interface SavingsModalProps {
  isOpen: boolean;
  onClose: () => void;
  savedAmount?: number;
  actualAmount?: number;
}

interface Styles {
  [key: string]: React.CSSProperties;
}

const styles: Styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  },
  modal: {
    position: "relative",
    backgroundColor: "white",
    borderRadius: "12px",
    padding: "24px",
    width: "250px",
    textAlign: "center",
  },
  closeButton: {
    position: "absolute",
    right: "16px",
    top: "16px",
    border: "none",
    background: "none",
    fontSize: "20px",
    cursor: "pointer",
    color: "#666",
  },
  iconContainer: {
    width: "48px",
    height: "48px",
    borderRadius: "50%",
    backgroundColor: "#e6f4ea",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto 16px",
  },
  icon: {
    color: "#34a853",
    fontSize: "24px",
  },
  amount: {
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "8px",
    fontFamily: "Plus Jakarta Sans",
    marginTop: "15px",
  },
  discount: {
    marginBottom: "16px",
    fontWeight: "600",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "12px",
    color: "#222026",
  },
  button: {
    width: "100%",
    padding: "12px",
    background: "none",
    color: "#1E799C",
    border: "none",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: "bold",
    cursor: "pointer",
  },
};

const CouponSuccessModal: React.FC<SavingsModalProps> = ({
  isOpen,
  onClose,
  savedAmount = 0,
  actualAmount = 0,
}) => {
  if (!isOpen) return null;

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Escape") {
      onClose();
    }
  };

  function calculatePercentage(actualValue: number, discount: number): string {
    return ((discount / actualValue) * 100).toFixed(2);
  }

  return (
    <div
      style={styles.overlay}
      onClick={handleOverlayClick}
      onKeyDown={handleKeyPress}
      role="dialog"
      aria-modal="true"
      tabIndex={-1}>
      <div style={styles.modal}>
        <button
          style={styles.closeButton}
          onClick={onClose}
          aria-label="Close modal">
          ×
        </button>

        {/* <div style={styles.iconContainer}> */}
        <DiscountPercentageSvg height={30} width={30} color="#068A0C" />
        {/* </div> */}

        <div style={styles.amount}>
          ₹{savedAmount.toLocaleString()} saved with this
          <br />
          coupon!
        </div>

        <div style={styles.discount}>
          FLAT {calculatePercentage(actualAmount, savedAmount)}% off
        </div>

        <button style={styles.button} onClick={onClose}>
          YAY!
        </button>
      </div>
    </div>
  );
};

export default CouponSuccessModal;
