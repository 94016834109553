import React, {useEffect, useState} from "react";
import "./Storefront.style.css";
import DynamicTabs from "../../../components/mobile/DynamicTabs/DynamicTabs";
import ReviewOverlay from "./Components/ReviewOverlay/ReviewOverlay";
import ProductSection from "./Components/ProductSection/ProductSection";
import AboutSection from "./Components/AboutSection/AboutSection";
import StoreInfo from "./Components/StoreInfo/StoreInfo";
import ZoopFooterSvg from "../../../components/SvgImages/ZoopFooterSvg/ZoopFooterSvg";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../interceptors/store";
import {
  getStoreFrontAboutThunk,
  getStoreFrontDetailsThunk,
  getStoreRatingCountThunk,
  getStoreReviewsThunk,
} from "../../../features/User/UserSlice";
import {getStoreProductsThunk} from "../../../features/Inventory/InventorySlice";
import {RatingCount, Review} from "../../../features/User/UserInterface";
import {useParams} from "react-router";
import SkeletonLoader from "./Components/Skeleton/StoreFrontSkeleton";
import ImageModal from "../../../components/modal/ImageModal/ImageModal";

type StoreFrontParams = {
  storeId: string;
};

const Storefront: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const {storeFront} = useSelector((state: RootState) => state.user);
  const {storeProducts} = useSelector((state: RootState) => state.inventory);
  const [isReviewOverlayOpen, setIsReviewOverlayOpen] = useState(false);
  const [reviews, setReviews] = useState<Review[]>([]);
  const [reviewCount, setReviewCount] = useState<number>(0);
  const [productCount, setProductCount] = useState<number>(0);
  const [storeRatingCount, setStoreRatingCount] = useState<RatingCount[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1); // Track the current page
  const [itemsPerPage] = useState<number>(10);
  const totalPages = Math.ceil(productCount / itemsPerPage);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control ImageModal
  const [currentImage, setCurrentImage] = useState<string | null>(null); // State to track the current image
  const [storeName, setStoreName] = useState("");
  const [storeId, setStoreId] = useState("");

  useEffect(() => {
    // Detect the subdomain
    const hostname = window.location.hostname;
    const subdomain = hostname.split(".")[0];

    // If the subdomain is not localhost or web-uat, process it
    if (subdomain !== "localhost" && subdomain !== "web-uat") {
      // Remove '-web-uat' from the subdomain if it exists
      const cleanSubdomain = subdomain.includes("-web-uat")
        ? subdomain.replace("-web-uat", "")
        : subdomain;

      setStoreName(cleanSubdomain); // Store the clean subdomain
    }
  }, []);

  const params = useParams<StoreFrontParams>();

  useEffect(() => {
    const fetchStoreDetails = async () => {
      setLoading(true);
      try {
        const action = await dispatch(
          getStoreFrontDetailsThunk({
            store_name: storeName,
            storeId: params?.storeId ?? "",
            type: "details",
          }),
        );

        if (getStoreFrontDetailsThunk.fulfilled.match(action)) {
          setStoreId(action.payload.data.store_id);
        }
      } catch (error) {
        console.error("Error fetching store details:", error);
      } finally {
        setLoading(false);
      }
    };

    if (storeName || params?.storeId) {
      fetchStoreDetails();
    }
  }, [storeName, dispatch]);

  useEffect(() => {
    const fetchStoreData = async () => {
      if (storeId) {
        setLoading(true);
        try {
          // First fetch the store's about details
          await dispatch(
            getStoreFrontAboutThunk({
              storeId: params?.storeId ?? storeId,
              type: "about",
            }),
          );

          // Then fetch reviews
          const reviewAction = await dispatch(
            getStoreReviewsThunk({
              storeId: params?.storeId ?? storeId,
            }),
          );

          if (getStoreReviewsThunk.fulfilled.match(reviewAction)) {
            const reviewDetails = reviewAction.payload.data.review_details;
            const reviewCount = reviewAction.payload.data.count;
            setReviews(reviewDetails);
            setReviewCount(reviewCount);
          }

          // Then fetch store ratings
          const ratingAction = await dispatch(
            getStoreRatingCountThunk({
              storeId: params?.storeId ?? storeId,
            }),
          );

          if (getStoreRatingCountThunk.fulfilled.match(ratingAction)) {
            setStoreRatingCount(ratingAction.payload.data.result);
          }

          // Fetch products for the first page
          callFetchProducts(storeId);
        } catch (error) {
          console.error("Error fetching store data:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchStoreData();
  }, [storeId, dispatch, params?.storeId, currentPage]);

  const callFetchProducts = async (storeId: string) => {
    try {
      const action = await dispatch(
        getStoreProductsThunk({
          store_id: params?.storeId ?? storeId,
          page: currentPage,
          limit: itemsPerPage,
        }),
      );
      if (getStoreProductsThunk.fulfilled.match(action)) {
        const productCount = action.payload.data.net_count;
        setProductCount(productCount);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const tabData = [
    {
      label: "Products",
      content: (
        <ProductSection
          products={storeProducts.data}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          totalPages={totalPages}
        />
      ),
    },

    {
      label: "Reviews",
      content: (
        <AboutSection
          storeName={storeFront?.details?.store_name}
          freeDeliveryAmount={undefined}
          email={storeFront.about?.email}
          deliveryDiscount={storeFront.details?.delivery_discount}
          storeId={storeFront.details?.store_id}
          address1={storeFront.about?.address_details_line1}
          address2={storeFront.about?.address_details_line2}
          city={storeFront.about?.city}
          pincode={storeFront.about?.pincode}
          phoneNumber={storeFront.about?.phone_no}
          setReviewOverlayOpen={setIsReviewOverlayOpen}
          profilePhoto={storeFront.details?.profile_url}
          reviews={reviews}
          reviewCount={reviewCount}
          productCount={productCount}
          storeRating={storeFront.about?.store_rating}
          ratings={storeRatingCount}
        />
      ),
    },
  ];

  return (
    <div className="storefront">
      {loading ? (
        <SkeletonLoader />
      ) : (
        <div>
          <StoreInfo
            storeName={storeFront?.details?.store_name}
            email={storeFront?.about?.email}
            freeDeliveryAmount={storeFront.details?.free_delivery_amount}
            profilePhoto={storeFront.details?.profile_url}
            isActive={storeFront.about?.is_active}
            storeRating={storeFront.about?.store_rating}
            pastMonthOrder={storeFront.about?.past_month_order_count}
            storeDescription={storeFront?.details?.description}
            productCount={storeProducts.count}
          />

          <DynamicTabs tabs={tabData} scrollable={false} />

          {reviewCount > 0 && (
            <ReviewOverlay
              isOpen={isReviewOverlayOpen}
              onClose={() => {
                setIsReviewOverlayOpen(false);
                setCurrentImage(null);
              }}
              reviews={reviews}
              reviewCount={reviewCount}
              setCurrentImage={image => {
                setCurrentImage(image);
                setIsModalOpen(true);
              }}
            />
          )}

          <ImageModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            imageSrc={currentImage ?? ""}
          />

          <footer className="storefront-footer">
            <div className="storefront-footer-box">
              <p className="storefront-footer-zoop">Welcome to ZOOP universe</p>
              <p className="storefront-footer-made-in">Made in Gurugram</p>
              <div className="storefront-footer-divider"></div>
              <div className="storefront-zoop-logo">
                <ZoopFooterSvg height={37} width={98} />
              </div>
            </div>
          </footer>
        </div>
      )}
    </div>
  );
};

export default Storefront;
