import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import "./CategoryProducts.styles.css";
import {SelectedFilters} from "../../ProductsPage";
import {AppDispatch, RootState} from "../../../../../interceptors/store";
import {
  GetProductsPayload,
  Product,
} from "../../../../../features/Product/ProductInterface";
import {getCategoryProductsThunk} from "../../../../../features/Product/ProductSlice";
import {convertIntoINR} from "../../../../../utils/helpers";
import {useNavigate} from "react-router";
import CategoryProductsSkeleton from "./CategoryProductsSkeleton";
import useMobileSize from "../../../../../hooks/useMobileSize";

interface CategoryProductsProps {
  categoryId?: string;
  subCategoryId?: string;
  selectedSortOption?: string;
  selectedFilters?: SelectedFilters;
  discount?: number;
  query?: string;
}

export const CategoryProducts = ({
  categoryId,
  subCategoryId,
  selectedSortOption,
  discount,
  selectedFilters,
  query,
}: CategoryProductsProps) => {
  const mobileSize = useMobileSize();
  const {categoryProducts} = useSelector((state: RootState) => state.product);

  const dispatch = useDispatch<AppDispatch>();

  const [loadingProducts, setLoadingProducts] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1); // Track the current page
  const limit = mobileSize ? 10 : 20; // Set how many products per page

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch products when the component is mounted or when page/filters change
    initiateGetProducts(currentPage);
  }, [
    currentPage,
    categoryId,
    subCategoryId,
    selectedSortOption,
    selectedFilters,
    query,
  ]);

  const isApiCallInProgress = useRef(false);
  const initiateGetProducts = async (page: number) => {
    if (isApiCallInProgress.current) return;
    isApiCallInProgress.current = true;

    await callFetchProducts(page);

    isApiCallInProgress.current = false;
  };

  const callFetchProducts = async (page: number) => {
    setLoadingProducts(true);

    const urlParams = new URLSearchParams(window.location.search);
    const categoriesParam = urlParams.get("categories");
    const subcategoriesParam = urlParams.get("subcategories");
    const sortParam = urlParams.get("sort");
    const queryParam = urlParams.get("query");

    const orderKey =
      selectedSortOption === "Price Low to High" ||
      selectedSortOption === "Price High to Low" ||
      sortParam?.toLowerCase() == "price-high to low"
        ? "price"
        : selectedSortOption === "Discount"
          ? "discount"
          : null;
    const orderType =
      selectedSortOption === "Price Low to High"
        ? "asc"
        : selectedSortOption === "Price High to Low" ||
            sortParam?.toLowerCase() == "price-high to low"
          ? "desc"
          : null;

    const product_name = query ? query : "";
    const payload: GetProductsPayload = {
      category_id: categoriesParam || categoryId || null,
      subcategory_id: subcategoriesParam || subCategoryId || null,
      page,
      limit, // Use the limit from state
      discount: discount || null,
      order_key: orderKey || null,
      order_type: orderType || null,
      product_name: queryParam || product_name || "",
    };

    try {
      const action = await dispatch(getCategoryProductsThunk({...payload}));
      if (getCategoryProductsThunk.fulfilled.match(action)) {
        // Successfully fetched products, no further action needed
        setLoadingProducts(false);
      }
    } catch (error) {
      setLoadingProducts(false);
      console.log("Error fetching products:", error);
    }
  };

  // Calculate total pages based on the products count
  const totalPages = () => {
    return Math.ceil(categoryProducts.count / limit);
  };

  // Get the products for the current page using array slicing
  // const getCurrentPageProducts = () => {
  //   const startIndex = (currentPage - 1) * limit;
  //   const endIndex = startIndex + limit;

  //   return categoryProducts.data.slice(startIndex, endIndex);
  // };

  // Function to render each product
  const renderItem = (item: Product, index: number) => {
    return (
      <div
        className={`category-products-product-card  ${item.product_total_quantity === 0 ? "out-of-stock" : ""}`}
        key={`${item.id}-${index}`}
        onClick={() => {
          navigate(`/product/${item.id}`);
        }}>
        <button className="category-product-card-button">
          <div className="category-product-card-body">
            <div className="category-product-image-container">
              {Number(item?.min_product_price) >
                Number(item?.min_selling_price) &&
                item?.product_total_quantity > 0 && (
                  <div className="discount-container">
                    <span className="discount-text">
                      {Math.ceil(
                        ((Number(item?.min_product_price) -
                          Number(item?.min_selling_price)) /
                          Number(item?.min_product_price)) *
                          100,
                      )}
                      % Off
                    </span>
                  </div>
                )}
              {item?.product_total_quantity === 0 && (
                <div className="discount-container sold-out">
                  <span className="discount-text">Sold Out</span>
                </div>
              )}
              <img
                src={
                  item.productImageDetails.length !== 0
                    ? item.productImageDetails[0].image_url
                    : "https://storage.googleapis.com/zoop-public-bucket/ZOOP-RECOMMENDS/productstock.png"
                }
                alt={item.product_name}
                className="category-product-image"
              />
            </div>
            <div className="category-product-details-container">
              <div className="category-product-name">
                {item.product_name ?? "Product Name"}
              </div>
              <div className="category-product-description">
                {item.product_description}
              </div>
              <div className="category-product-price-container">
                <span
                  className={`price ${Number(item.min_product_price) > Number(item.min_selling_price) ? "discounted" : ""}`}>
                  ₹{convertIntoINR(item.min_selling_price)}
                </span>
                {Number(item.min_product_price) >
                  Number(item.min_selling_price) && (
                  <span className="old-price">
                    ₹{convertIntoINR(item.min_product_price)}
                  </span>
                )}
              </div>
            </div>
          </div>
        </button>
      </div>
    );
  };

  return (
    <div className="products-container">
      {loadingProducts ? (
        <div className="category-product-list ">
          {Array.from({length: 6}).map((_, index) => (
            <CategoryProductsSkeleton key={index} />
          ))}
        </div>
      ) : categoryProducts.data.length !== 0 ? (
        <div className="category-product-list ">
          {categoryProducts.data.map((item, index) => renderItem(item, index))}
        </div>
      ) : (
        <div className="category-products-no-pproducts">No products</div>
      )}

      {/* Pagination Controls */}
      <div style={{paddingBottom: "3%"}}>
        {categoryProducts.data.length > 0 && (
          <div className="storefront-pagination-container">
            <button
              className="storefront-pagination-arrow"
              disabled={currentPage === 1}
              onClick={() => setCurrentPage((prev: number) => prev - 1)}>
              &#8249;
            </button>
            <div className="storefront-pagination-numbers">
              Page
              <span
                className={`storefront-pagination-number ${currentPage === 1 ? "active" : ""}`}
                onClick={() => setCurrentPage(1)}>
                1
              </span>
              {currentPage > 3 && (
                <span className="storefront-pagination-dots">...</span>
              )}
              {currentPage > 2 && (
                <span
                  className="storefront-pagination-number"
                  onClick={() => setCurrentPage(currentPage - 1)}>
                  {currentPage - 1}
                </span>
              )}
              {currentPage !== 1 && currentPage !== totalPages() && (
                <span className="storefront-pagination-number active">
                  {currentPage}
                </span>
              )}
              {currentPage < totalPages() - 1 && (
                <span
                  className="storefront-pagination-number"
                  onClick={() => setCurrentPage(currentPage + 1)}>
                  {currentPage + 1}
                </span>
              )}
              {currentPage < totalPages() - 2 && (
                <span className="storefront-pagination-dots">...</span>
              )}
              {totalPages() > 1 && (
                <span
                  className={`storefront-pagination-number ${
                    currentPage === totalPages() ? "active" : ""
                  }`}
                  onClick={() => setCurrentPage(totalPages)}>
                  {totalPages()}
                </span>
              )}
            </div>
            <button
              className="storefront-pagination-arrow"
              disabled={currentPage === totalPages()}
              onClick={() => setCurrentPage((prev: number) => prev + 1)}>
              &#8250;
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
